import SdkAuth, { TokenInfo, TokenProvider } from "@commercetools/sdk-auth"
import {
  createClient,
  Dispatch as Next,
  MiddlewareRequest,
  MiddlewareResponse
} from "@commercetools/sdk-client-v2"
import { ClientRequest } from "@commercetools/sdk-client-v2/dist/declarations/src/types/sdk"
import { createHttpMiddleware } from "@commercetools/sdk-middleware-http"
import {
  ApiRoot,
  Cart,
  createApiBuilderFromCtpClient,
  Customer,
  ClientResponse
} from "@commercetools/platform-sdk"
import { ByProjectKeyRequestBuilder } from "@commercetools/platform-sdk/dist/declarations/src/generated/client/by-project-key-request-builder"
import { ApiRequest } from "@commercetools/platform-sdk/dist/declarations/src/generated/shared/utils/requests-utils"
import * as Sentry from "@sentry/react"
import { last } from "./Utils"
import fetch from "isomorphic-unfetch"
import { v4 as uuidv4 } from "uuid"
import { AccessTokenState } from "../auth/AccessTokenState"
import { axiosDefaultClient } from "../AxiosClient"
import { AppDispatch } from "../State"
import { isBrowser } from "../utils/Browser"
import { createLogger } from "../utils/createLogger"
import { commerceToolsAuth, commerceToolsClient } from "./CommerceTools"
import { createLoggerMiddleware } from "./CommerceToolsMiddleware"
import FrontendConfig, { Config } from "./Config"
import { withAuth } from "./withAuth"

const logger = createLogger("CommerceToolsClient")

type FetchFn = (input: RequestInfo, init?: RequestInit) => Promise<Response>

const fetchWithCorrelationId: FetchFn = (
  request: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  const correlationId = uuidv4()
  const headers = {
    ...(init?.headers || {}),
    "x-correlation-id": correlationId
  }
  const initWithCorrelationId = {
    ...(init || {}),
    headers
  }
  return fetch(request, initWithCorrelationId)
}

export interface CommerceToolsClient {
  signIn: (
    email: string,
    password: string,
    recaptcha: Promise<string | undefined>,
    cartId?: string | undefined
  ) => Promise<{ token: TokenInfo; customer: Customer; cart?: Cart }>
  logout: () => Promise<void>
  execute<T>(
    fn: (project: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>>
}

export interface ServerSideCommerceToolsClientI {
  logout: () => Promise<void>
  execute<T>(
    fn: (project: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>>
}

export enum ClientType {
  SessionScoped = "session-scoped",
  GlobalClient = "global-client"
}

const executeRequestBuilder = (
  currentAccessToken: () => TokenInfo | undefined,
  dispatch: AppDispatch
) => {
  return async (request: ClientRequest): Promise<ClientResponse> => {
    return withAuth(
      currentAccessToken,
      dispatch,
      new Date()
    )(() => {
      return commerceToolsClient.execute({
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${currentAccessToken()?.access_token}`
        }
      })
    })
  }
}

export class SimpleCommerceToolsClient implements CommerceToolsClient {
  private readonly config: Config
  private readonly dispatch: AppDispatch
  private readonly executeRequest: (
    request: ClientRequest
  ) => Promise<ClientResponse>
  private readonly apiRoot: ApiRoot

  constructor(
    currentAccessToken: () => TokenInfo | undefined,
    dispatch: AppDispatch,
    config: Config
  ) {
    this.config = config
    this.dispatch = dispatch
    this.executeRequest = executeRequestBuilder(currentAccessToken, dispatch)

    this.apiRoot = new ApiRoot({
      executeRequest: this.executeRequest
    })
  }

  public logout(): Promise<void> {
    return commerceToolsAuth
      .anonymousFlow()
      .then(it => this.dispatch(AccessTokenState.actions.setToken(it)))
      .then(() => Promise.resolve())
  }

  public signIn(
    email: string,
    password: string,
    recaptcha: Promise<string | undefined>,
    cartId: string | undefined
  ): Promise<{ token: TokenInfo; customer: Customer; cart?: Cart }> {
    return recaptcha
      .then(recaptchaToken =>
        axiosDefaultClient.post<{
          token: TokenInfo
          customer: Customer
          cart?: Cart
        }>("/api/login", {
          payload: { email, password, cartId },
          meta: { recaptchaToken }
        })
      )
      .then(response => {
        if (response.status > 299)
          return Promise.reject(`Invalid response: ${response.status}`)
        this.dispatch(AccessTokenState.actions.setToken(response.data.token))
        return response.data
      })
      .catch(err => {
        logger.error(err)
        throw err
      })
  }

  public execute<T>(
    fn: (project: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>> {
    return fn(
      this.apiRoot.withProjectKey({ projectKey: this.config.projectKey })
    ).execute()
  }
}

export class StatefulCommerceToolsClient
  implements ServerSideCommerceToolsClientI
{
  private readonly tokenProvider: TokenProvider
  private readonly project: ByProjectKeyRequestBuilder

  constructor(
    config: Config,
    clientType: ClientType = ClientType.SessionScoped,
    accessToken: TokenInfo | undefined,
    fetchTokenStrategy: (sdkAuth: SdkAuth) => Promise<TokenInfo>
  ) {
    this.tokenProvider = new TokenProvider(
      {
        sdkAuth: new SdkAuth({
          host: config.authUrl,
          projectKey: config.projectKey,
          disableRefreshToken: false,
          credentials: {
            clientId: config.credentials.clientId,
            clientSecret: config.credentials.clientSecret
          },
          scopes: config.scopes,
          fetch: fetchWithCorrelationId
        }),
        fetchTokenInfo: fetchTokenStrategy,
        onTokenInfoChanged: (tokenInfo: TokenInfo) => {
          if (
            isBrowser() &&
            clientType === ClientType.SessionScoped &&
            (document?.URL || "").includes("/checkout")
          ) {
            Sentry.captureMessage(
              `Access Token for ${clientType} client changed during checkout`,
              {
                extra: {
                  url: document?.URL,
                  accessTokenSubstring: last(8, tokenInfo?.access_token),
                  refreshTokenSubstring: last(8, tokenInfo?.refresh_token)
                },
                level: "info"
              }
            )
          }
        }
      },
      accessToken
    )

    const httpMiddleware = createHttpMiddleware({
      ...config,
      fetch: fetchWithCorrelationId,
      host: config.apiUrl
    })

    const loggerMiddleware = createLoggerMiddleware()

    const authMiddleware =
      (next: Next): Next =>
      (request: MiddlewareRequest, response: MiddlewareResponse) => {
        if (
          (request.headers && request.headers.authorization) ||
          (request.headers && request.headers.Authorization)
        ) {
          next(request, response)
          return
        }

        this.getToken()
          .catch(error => {
            Sentry.captureMessage(
              "CommerceToolsClient AuthMiddleware failure",
              {
                extra: {
                  message: error.message
                },
                level: "warning"
              }
            )
            throw error
          })
          .then(tokenInfo => {
            request.headers = {
              ...request.headers,
              Authorization: `Bearer ${tokenInfo.access_token}`
            }
            next(request, response)
          })
      }

    const client = createClient({
      middlewares: [authMiddleware, httpMiddleware, loggerMiddleware]
    })

    this.project = createApiBuilderFromCtpClient(client).withProjectKey({
      projectKey: config.projectKey
    })
  }

  public execute<T>(
    fn: (project: ByProjectKeyRequestBuilder) => ApiRequest<T>
  ): Promise<ClientResponse<T>> {
    return fn(this.project).execute()
  }

  public logout = (): Promise<void> => {
    this.tokenProvider.fetchTokenInfo = sdkAuth => sdkAuth.anonymousFlow()
    this.tokenProvider.invalidateTokenInfo()
    return Promise.resolve()
  }

  private getToken(): Promise<TokenInfo> {
    return this.tokenProvider.getTokenInfo().catch(error => {
      Sentry.captureMessage("Failed to fetch new token from CommerceTools", {
        extra: {
          message: error.message,
          accessTokenSubstring: last(
            8,
            this.tokenProvider.tokenInfo?.access_token
          ),
          refreshTokenSubstring: last(
            8,
            this.tokenProvider.tokenInfo?.refresh_token
          )
        },
        level: "warning"
      })

      return this.logout().then(() => this.tokenProvider.getTokenInfo())
    })
  }
}

const maintainSessionWith = (refreshToken: string) => {
  return (sdkAuth: SdkAuth) =>
    sdkAuth
      .refreshTokenFlow(refreshToken)
      .then(t => ({ ...t, refresh_token: refreshToken }))
}

export const InBrowserTokenStrategy: (
  accessToken: TokenInfo | undefined
) => (sdkAuth: SdkAuth) => Promise<TokenInfo> = (
  accessToken: TokenInfo | undefined
) => {
  return async (sdkAuth: SdkAuth) => {
    let token: TokenInfo
    if (accessToken) {
      logger.info("Initialising client with stored token")
      token = accessToken
    } else {
      logger.info("Initialising client with anonymous token")
      token = await sdkAuth.anonymousFlow()
    }

    return maintainSessionWith(token.refresh_token)(sdkAuth)
  }
}

export const OldServerSideCommerceToolsClient = new StatefulCommerceToolsClient(
  FrontendConfig,
  ClientType.GlobalClient,
  undefined,
  (sdkAuth: SdkAuth) => sdkAuth.clientCredentialsFlow()
)

export const ServerSideCommerceToolsClient = OldServerSideCommerceToolsClient
