import { TokenInfo } from "@commercetools/sdk-auth"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

export interface AccessTokenStateI {
  token: TokenInfo | undefined
  anonymousToken: TokenInfo | undefined
}

const initialState: AccessTokenStateI = {
  token: undefined,
  anonymousToken: undefined
}
export const AccessTokenState = createSlice({
  name: "accessToken",
  initialState,
  reducers: {
    setAnonymousToken(state, action: PayloadAction<TokenInfo>) {
      return { ...state, anonymousToken: action.payload }
    },
    setToken(state, action: PayloadAction<TokenInfo>) {
      return { ...state, token: action.payload }
    },
    clearAnonymousToken(state, action: PayloadAction<void>) {
      return { ...state, anonymousToken: undefined }
    },
    clear(state, action: PayloadAction<void>) {
      return { ...state, token: undefined, anonymousToken: undefined }
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})
