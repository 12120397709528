import { ParsedUrlQuery } from "querystring"
import { ProductFacets } from "../category/ProductFacetFactory"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { DomainCategory } from "../product/models/DomainCategory"
import { Shelf } from "../shelves/ShelvesDal"
import { PARAMETER_NAME } from "./Constants"
import { onDynamicCategory } from "../filters/TagFilterHooks"

export interface CategoryPageParams {
  categorySlug: string
  variantFilter: string
  tagFilter: string
}

export interface CategoryProps {
  params: CategoryPageParams
  category: DomainCategory | null
  facets: ProductFacets
  products: CoreProductInformation[]
  shelves: Shelf[]
}

export interface CategoryPageProps {
  categoryPage?: CategoryProps
}

export const getCategoryPageParams = (
  query: ParsedUrlQuery | undefined
): CategoryPageParams | undefined => {
  const pathParameters = (query && query[PARAMETER_NAME]) || []
  const filter = (query && (query["vf"] as string)) || undefined
  const tag = (query && (query["tag"] as string)) || undefined

  if (pathParameters.length >= 1) {
    const [categorySlug] = pathParameters

    const result = {
      categorySlug,
      variantFilter: filter || "",
      tagFilter: tag || ""
    }

    return (
      onDynamicCategory(categorySlug, (dynamicCategory: string) => {
        return {
          ...result,
          categorySlug: categorySlug.replace(`-${dynamicCategory}`, ""),
          tagFilter: dynamicCategory
        }
      }) || result
    )
  } else {
    return undefined
  }
}
