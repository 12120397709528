import { PaymentMethod, PaymentAmount, PaymentData } from "@adyen/adyen-web"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AdyenSession } from "../checkout/payment/PaymentButtons"
import { useRootSelector } from "../State"

export interface AdyenStateI {
  adyenPaymentType?: string
  adyenPaymentId?: string
  adyenPayment?: {
    id: string
    session: AdyenSession
    paymentMethods: PaymentMethod[]
    amount: PaymentAmount
  }
  adyenAdditionalDetailsPaymentData?: string
  adyenPaymentState?: PaymentData
}

export const AdyenState = createSlice({
  name: "adyen",
  initialState: {} as AdyenStateI,
  reducers: {
    setAdyenPaymentType(
      state: AdyenStateI,
      { payload: adyenPaymentType }: PayloadAction<string>
    ) {
      return {
        ...state,
        adyenPaymentType,
        adyenPaymentState: undefined
      }
    },
    setAdyenPayment(
      state: AdyenStateI,
      {
        payload: adyenPayment
      }: PayloadAction<
        | {
            id: string
            session: AdyenSession
            paymentMethods: PaymentMethod[]
            amount: PaymentAmount
          }
        | undefined
      >
    ) {
      return {
        ...state,
        adyenPayment
      }
    },
    setAdyenPaymentData(
      state: AdyenStateI,
      { payload: adyenPaymentState }: PayloadAction<PaymentData | undefined>
    ) {
      return {
        ...state,
        adyenPaymentState
      }
    },
    clearPaymentState(state) {
      return {}
    }
  }
})

export const useAdyenPayment: () =>
  | { id: string; session: AdyenSession }
  | undefined = () => useRootSelector(it => it.adyenState.adyenPayment)

export const useAdyenPaymentData: () => PaymentData | undefined = () =>
  useRootSelector(it => it.adyenState.adyenPaymentState)

export const useSelectedAdyenPaymentType: () => string | undefined = () =>
  useRootSelector(it => it.adyenState.adyenPaymentType)

export const usePlannedAdyenAmount: () => PaymentAmount | undefined = () =>
  useRootSelector(it => it.adyenState.adyenPayment?.amount)
