import { useRef } from "react"

export const useScrollToElement = () => {
  const ref = useRef<any>()

  const scrollToElement = () => {
    ref.current?.scrollIntoView()
  }

  return {
    ref,
    scrollToElement
  }
}
