import { useRootSelector, useAppDispatch } from "../State"
import { ErrorMessage, ErrorState } from "./ErrorState"
import { ErrorType } from "./ErrorType"

export const useErrorMessage = (errors: ErrorType) =>
  useRootSelector(it => it.errorMessageState[errors])

export const useErrorApi = () => {
  const dispatch = useAppDispatch()
  const errorState = useRootSelector(it => it.errorMessageState)

  return {
    setError: (errorKey: ErrorType, message: ErrorMessage) => {
      dispatch(ErrorState.actions.setMessage({ errorKey, message }))
    },
    readError: (errorKey: ErrorType): ErrorMessage | undefined => {
      const error = errorState[errorKey]
      dispatch(ErrorState.actions.clearMessage(errorKey))
      return error
    },
    clear: (errorKey: ErrorType) => {
      dispatch(ErrorState.actions.clearMessage(errorKey))
    },
    clearAll: () => {
      dispatch(ErrorState.actions.clearAll())
    }
  }
}

export const useTypedErrorApi = (errorKey: ErrorType) => {
  const error = useErrorMessage(errorKey)
  const api = useErrorApi()

  return {
    error,
    setError: (message: ErrorMessage) => {
      return api.setError(errorKey, message)
    },
    readError: (): ErrorMessage | undefined => {
      return api.readError(errorKey)
    },
    clear: () => {
      return api.clear(errorKey)
    }
  }
}
