import { Cart } from "@commercetools/platform-sdk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

const cartIdKey: string = "cartId"

export const CartId =  {

  clear() {
    localStorage.removeItem(cartIdKey)
  },
  set(id: string) {
    localStorage.setItem(cartIdKey, id)
  },
  value() : string | null {
    return localStorage.getItem(cartIdKey)
  }
}

export interface CartStateI {
  cart?: Cart
}

const initialState: CartStateI = {}
export const CartState = createSlice({
  name: "cartState",
  initialState,
  reducers: {
    setCart(state: CartStateI, action: PayloadAction<Cart>) {
      return { ...state, cart: action.payload }
    },
    clearCart(state: CartStateI) {
      return {}
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})

export interface CartNotificationProduct {
  name: string
  price: string
  discountedPrice: string
  size: string
  color: string
  imageUrl: string
  imageAlt: string
}

export interface CartNotificationStatI {
  product?: CartNotificationProduct
}

const initialCartNotificationState: CartNotificationStatI = {}
export const CartNotificationState = createSlice({
  name: "cartNotificationState",
  initialState: initialCartNotificationState,
  reducers: {
    notify(state, action: PayloadAction<CartNotificationProduct>) {
      return { ...state, product: action.payload }
    },
    clear() {
      return {}
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialCartNotificationState)
  }
})
