export const isBrowser = (): boolean => typeof window !== "undefined"

export function ifBrowser<T>(fn: () => T): T | undefined {
  if (isBrowser()) {
    return fn()
  } else {
    return undefined
  }
}

const scrollToFirstDomElement = (selector, delayMs = 0) => {
  if (!isBrowser()) return
  setTimeout(() => {
    const elem = document.querySelector(selector) as HTMLElement | null
    if (elem && !elementIsInViewport(elem)) {
      const rect = elem.getBoundingClientRect()
      window.scrollTo({
        top: rect.top + window.scrollY - rect.height - 100,
        behavior: "smooth"
      })
    }
  }, delayMs)
}

export const scrollToFirstErrorCard = ({ delay = 0 } = {}) =>
  scrollToFirstDomElement("[class*=ErrorCard]", delay)

export const elementIsInViewport = elem => {
  if (!isBrowser || !elem) return false
  const {
    top,
    left,
    bottom,
    right,
    height: elemHeight
  } = elem.getBoundingClientRect()
  const height = window.innerHeight || document.documentElement.clientHeight
  const width = window.innerWidth || document.documentElement.clientWidth
  return (
    top + elemHeight >= 0 &&
    (bottom <= height || top <= height) &&
    (left <= width || right <= width)
  )
}
