export class Paginator<T> {
  private readonly initialRequest: () => Promise<T>
  private readonly next: (result: T) => Array<Promise<T>>
  private readonly maximumPages: number

  constructor(
    initialRequest: () => Promise<T>,
    next: (result: T) => Array<Promise<T>>,
    maximumPages: number
  ) {
    this.initialRequest = initialRequest
    this.next = next
    this.maximumPages = maximumPages
  }

  public async fetchAll(): Promise<T[]> {
    const accumulate = (accumulator: T[], nextRequests: Array<Promise<T>>) => {
      if (accumulator.length >= this.maximumPages) {
        return Promise.resolve(accumulator)
      }

      if (nextRequests.length === 0) {
        return Promise.resolve(accumulator)
      }
      return Promise.all(nextRequests).then(results => {
        return accumulate(
          accumulator.concat(results),
          results.flatMap(result => this.next(result))
        )
      })
    }
    return accumulate([], [this.initialRequest()])
  }
}
