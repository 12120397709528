//jsxhook

import { useRouter } from "next/router"
import { getCategoryPageParams } from "../routing/CategoryPageParams"

export const useUrlSearch = searchTerm => {
  const pageParams = useRouter().query

  return getCategoryPageParams(pageParams)?.tagFilter || ""
}
