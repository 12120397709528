import { Country } from "@sixty-six-north/i18n"
import { DEFAULT_REGION } from "../i18n/Region"
import { Stores, Store } from "./Stores"

const countryToStoreMapping = {
  [Country.gb]: Stores.UK,
  [Country.dk]: Stores.DK,
  [Country.us]: Stores.US,
  [Country.is]: Stores.IS,
  [Country.eu]: Stores.EU
}
export const countryToStore = (country: Country): Store => {
  return countryToStoreMapping[country]
}
export const DEFAULT_STORE = countryToStore(DEFAULT_REGION.country)
