import { Address } from "@commercetools/platform-sdk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useRootSelector } from "../../State"

export enum ShippingAddressType {
  NewAddress = 0,
  SavedAddress = 1,
  Unknown = 999
}

export interface InformationPageStateI {
  emailAddress?: string
  shippingAddressType?: ShippingAddressType
  shippingAddress?: Address
  showLoginModal: boolean
}

export const InformationPageState = createSlice({
  name: "CustomerInformationPageState",
  initialState: {
    showLoginModal: false
  } as InformationPageStateI,
  reducers: {
    setEmailAddress(
      state: InformationPageStateI,
      email: PayloadAction<string>
    ) {
      return { ...state, emailAddress: email.payload }
    },
    setShippingAddressType(
      state: InformationPageStateI,
      type: PayloadAction<ShippingAddressType>
    ) {
      return { ...state, shippingAddressType: type.payload }
    },
    setAddress(state: InformationPageStateI, address: PayloadAction<Address>) {
      return { ...state, shippingAddress: address.payload }
    },
    showLogin(state: InformationPageStateI) {
      return { ...state, showLoginModal: true }
    },
    hideLogin(state: InformationPageStateI) {
      return { ...state, showLoginModal: false }
    },
    clear(state: InformationPageStateI) {
      return { showLoginModal: false }
    }
  }
})

export const useInformationPageState = () =>
  useRootSelector(it => it.informationPageState)
