/**
 * Given a paginated result, returns a list of offsets for the next
 * pages of results
 * @param result
 */
export const getNextOffsets = (result: {
  total?: number
  count?: number
  offset?: number
}): number[] => {
  if (result.offset === 0 && result.total && result.count) {
    return Array(Math.ceil(result.total / result.count))
      .fill(result.count)
      .map((count, page) => count * page)
      .slice(1)
  }
  return []
}
