import { DiscountedPrice, TypedMoney } from "@commercetools/platform-sdk"
import { isTouchDevice } from "@sixty-six-north/ui-system"
import { ImageView } from "../category/ProductListing"
import { CoreProductInformation } from "../product/models/DetailedProductInformation"
import { Price } from "../product/models/Price"
import { SimpleAsset } from "../product/models/SimpleAsset"

const IMAGES_DOMAIN = "https://images.66north.com"

export const getProductImagesForView = (
  view: ImageView | undefined,
  matchingImages: SimpleAsset[]
) => {
  const modelImage = matchingImages.find(it => it.tags.includes("Model"))
  const productImage = matchingImages.find(it => it.tags.includes("Product"))
  const untaggedImages = matchingImages.filter(
    it => !it.tags.includes("Product") && !it.tags.includes("Model")
  )
  const firstUntaggedImage = untaggedImages[0]
  const firstImage: string = matchingImages[0]?.url || ""

  let imageUrl: string
  let hoverImageUrl: string

  switch (view) {
    case "PRODUCT":
      imageUrl =
        productImage?.url ||
        modelImage?.url ||
        firstUntaggedImage?.url ||
        firstImage
      hoverImageUrl =
        modelImage && imageUrl !== modelImage?.url
          ? modelImage?.url
          : firstUntaggedImage?.url || ""
      break
    case "MODEL":
    default:
      imageUrl =
        modelImage?.url ||
        productImage?.url ||
        firstUntaggedImage?.url ||
        firstImage
      hoverImageUrl =
        productImage && imageUrl !== productImage?.url
          ? productImage?.url
          : firstUntaggedImage?.url || ""
      break
  }

  if (imageUrl === hoverImageUrl)
    hoverImageUrl = untaggedImages.length > 1 ? untaggedImages[1]?.url : ""

  if (isTouchDevice()) {
    hoverImageUrl = ""
  }

  if (imageUrl && !imageUrl.startsWith("http")) {
    imageUrl = IMAGES_DOMAIN + imageUrl
  }
  if (hoverImageUrl && !hoverImageUrl.startsWith("http")) {
    hoverImageUrl = IMAGES_DOMAIN + hoverImageUrl
  }
  return {
    imageUrl,
    hoverImageUrl
  }
}

export const hierarchicalProductHasAnyStock = (
  product: CoreProductInformation
) => {
  return Object.values(product.colorways).some(c => c.inStock)
}

const filterPricesForCurrency = (price: Price, currency: string = "EUR") => {
  return (
    price.value.currencyCode === currency || price.value.currencyCode === "EUR"
  )
}

export const transformPricesForCurrency = (
  prices?: Price[],
  currency?: string
): Price[] => {
  return (
    (prices || [])
      .filter(p => filterPricesForCurrency(p, currency))
      .map(p => {
        const price: { value: TypedMoney; discounted?: DiscountedPrice } = {
          value: p.value
        }
        if (p.discounted) price.discounted = p.discounted
        return price
      }) || []
  )
}
