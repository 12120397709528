export const filterTruthy = <T>(t: T | undefined | null): t is T => {
  return !!t
}

export const filterUndefinedKeys = <T extends Record<string, unknown>>(
  actual: T
): T => {
  return Object.keys(actual).reduce((obj, key) => {
    if (actual[key] !== undefined && actual[key] !== null) {
      obj[key] = actual[key]
    }
    return obj
  }, {}) as T
}
