import { EnhancedStore } from "@reduxjs/toolkit"
import FrontendConfig from "./Config"
import React, { useEffect, ReactNode } from "react"
import { useAppDispatch, useAppStore } from "../State"
import {
  CommerceToolsClient,
  SimpleCommerceToolsClient
} from "./CommerceToolsClient"
import { withAuth } from "./withAuth"

const currentAccessTokenFrom = (store: EnhancedStore) => {
  return () => store.getState().accessTokenState?.token
}

export const simpleClient = new SimpleCommerceToolsClient(
  () => {
    throw Error("Uninitialised CommerceToolsClient")
  },
  () => {
    throw Error("Uninitialised CommerceToolsClient")
  },
  FrontendConfig
)

export const UserClientContext =
  React.createContext<CommerceToolsClient>(simpleClient)

export const CommerceToolsClientProvider: React.FC<{
  children?: ReactNode
}> = ({ children }) => {
  const store = useAppStore()
  const dispatch = useAppDispatch()

  const client = new SimpleCommerceToolsClient(
    currentAccessTokenFrom(store),
    dispatch,
    FrontendConfig
  )

  useEffect(() => {
    withAuth(
      () => store.getState().accessTokenState?.token,
      dispatch
    )(() => Promise.resolve(true))
  }, [])

  return (
    <UserClientContext.Provider value={client}>
      {children}
    </UserClientContext.Provider>
  )
}
