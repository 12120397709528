import { Address } from "@commercetools/platform-sdk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { useRootSelector } from "../../State"
import { BillingAddressType } from "./validation/PaymentValidation"

export enum PaymentProvider {
  Borgun = "BORGUN",
  NetGiro = "NETGIRO",
  PayPal = "PAYPAL",
  ApplePay = "APPLEPAY",
  GiftCard = "GIFTCARD",
  Adyen = "ADYEN"
}

export const DisallowedPaymentGroups = {
  [PaymentProvider.Borgun]: [
    PaymentProvider.NetGiro,
    PaymentProvider.PayPal,
    PaymentProvider.ApplePay,
    PaymentProvider.Adyen
  ],
  [PaymentProvider.Adyen]: [
    PaymentProvider.Borgun,
    PaymentProvider.NetGiro,
    PaymentProvider.PayPal,
    PaymentProvider.ApplePay,
    PaymentProvider.GiftCard
  ],
  [PaymentProvider.NetGiro]: [
    PaymentProvider.Borgun,
    PaymentProvider.PayPal,
    PaymentProvider.ApplePay,
    PaymentProvider.Adyen
  ],
  [PaymentProvider.PayPal]: [
    PaymentProvider.Borgun,
    PaymentProvider.NetGiro,
    PaymentProvider.ApplePay,
    PaymentProvider.Adyen
  ],
  [PaymentProvider.ApplePay]: [
    PaymentProvider.Borgun,
    PaymentProvider.NetGiro,
    PaymentProvider.PayPal,
    PaymentProvider.Adyen
  ]
}

export interface PaymentStateI {
  paymentProviders: PaymentProvider[]
  billingAddress?: Address
  billingAddressType?: BillingAddressType
  billingAddressVat?: string
}

const initialState = {
  paymentProviders: []
} as PaymentStateI
export const PaymentProviderState = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    setBillingAddressType(
      state: PaymentStateI,
      action: PayloadAction<BillingAddressType>
    ) {
      return {
        ...state,
        billingAddress: undefined,
        billingAddressType: action.payload
      }
    },

    setBillingAddress(
      state: PaymentStateI,
      action: PayloadAction<Address | undefined>
    ) {
      return {
        ...state,
        billingAddress: action.payload
      }
    },

    setBillingAddressVat(
      state: PaymentStateI,
      action: PayloadAction<string | undefined>
    ) {
      return {
        ...state,
        billingAddressVat: action.payload
      }
    },

    setBillingAddressSameAsShippingAddress(
      state: PaymentStateI,
      action: PayloadAction<Address | undefined>
    ) {
      return {
        ...state,
        billingAddress: action.payload,
        billingAddressType: BillingAddressType.SameAsShipping
      }
    },

    setSavedBillingAddress(
      state: PaymentStateI,
      action: PayloadAction<Address>
    ) {
      return {
        ...state,
        billingAddress: action.payload,
        billingAddressType: BillingAddressType.SavedAddress
      }
    },

    setNewBillingAddress(state: PaymentStateI, action: PayloadAction<Address>) {
      return {
        ...state,
        billingAddress: action.payload,
        billingAddressType: BillingAddressType.NewAddress
      }
    },

    setPaymentProviders(
      state: PaymentStateI,
      action: PayloadAction<PaymentProvider[]>
    ) {
      return { ...state, paymentProviders: [...new Set(action.payload)] }
    },

    removePaymentProviders(
      state: PaymentStateI,
      action: PayloadAction<PaymentProvider[]>
    ) {
      const providers = new Set(state.paymentProviders || [])

      action.payload.forEach(it => providers.delete(it))

      return {
        ...state,
        paymentProviders: [...providers]
      }
    },
    addPaymentProvider(
      state: PaymentStateI,
      action: PayloadAction<PaymentProvider>
    ) {
      const providers = new Set(state.paymentProviders || [])

      providers.add(action.payload)

      return {
        ...state,
        paymentProviders: [...providers]
      }
    },

    clearPaymentProvider(state: PaymentStateI) {
      return { paymentProviders: [] }
    },

    removePaymentProvider(
      state: PaymentStateI,
      action: PayloadAction<PaymentProvider>
    ) {
      const providers = new Set(state.paymentProviders || [])

      providers.delete(action.payload)

      return {
        ...state,
        paymentProviders: [...providers]
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})

export const usePaymentProviderState = (): PaymentProvider[] =>
  useRootSelector(it => it.paymentState.paymentProviders || [])

export const useBillingAddress = () =>
  useRootSelector(it => it.paymentState.billingAddress)

export const useBillingAddressType = () =>
  useRootSelector(it => it.paymentState.billingAddressType)
