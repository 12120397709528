import { DomainCategory } from "../product/models/DomainCategory"
import { useAllCategories } from "./CategoryHooks"

export const useCategory = (category: DomainCategory) => {
  const allCategories = useAllCategories()

  return {
    subCategoriesSortedByOrderHint() {
      return allCategories
        .filter(it => it.ancestors.filter(a => a.id === category.id).length > 0)
        .sort(byOrderHint)
    }
  }
}

export const byOrderHint = (left: DomainCategory, right: DomainCategory) =>
  left.orderHint > right.orderHint ? -1 : 1
