import { TokenInfo } from "@commercetools/sdk-auth"
import {
  configureStore,
  EnhancedStore,
  createListenerMiddleware,
  ListenerMiddleware,
  ThunkMiddleware,
  ThunkDispatch,
  Tuple,
  Middleware
} from "@reduxjs/toolkit"
import { useDispatch, useSelector, useStore } from "react-redux"
import { UnknownAction, combineReducers } from "redux"
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { AdyenState } from "./adyen/AdyenState"
import { AccessTokenState } from "./auth/AccessTokenState"
import { BorgunState } from "./borgun/BorgunState"
import { CartNotificationState, CartState, CartId } from "./cart/CartState"
import { OrderState } from "./cart/OrderState"
import { CheckoutState } from "./checkout/CheckoutState"
import { InformationPageState } from "./checkout/customerInformation/InformationPageState"
import { GiftCardState } from "./checkout/payment/GiftCardState"
import { PaymentProviderState } from "./checkout/payment/PaymentProviderState"
import { AppConfig } from "./Config"
import { CustomerState } from "./customer/CustomerState"
import { ErrorState } from "./error/ErrorState"
import { RegionState } from "./i18n/RegionState"
import { LayoutState } from "./layout/LayoutState"
import { NetgiroState } from "./netgiro/NetgiroState"
import logger from "./utils/logger"
import { apiSlice } from "./app/api/[store]/shipping-methods/rtk-query"

const migrateAccessToken = async state => {
  const readToken = async (currentState): Promise<TokenInfo> => {
    try {
      const oldState = localStorage.getItem("state")
      const parsedState = JSON.parse(oldState || "{}")
      const token = parsedState?.accessTokenState?.token
      if (!!token) {
        return { ...currentState, token }
      } else {
        return currentState
      }
    } catch (e) {
      return currentState
    }
  }
  const clearState = async currentState => {
    try {
      localStorage.removeItem("state")
    } catch (e) {
      // pass
    }
    return currentState
  }

  return await readToken(state).then(clearState)
}

export const appReducer = combineReducers({
  accessTokenState: persistReducer(
    {
      key: "accessToken",
      storage,
      version: 1,
      migrate: state => migrateAccessToken(state)
    },
    AccessTokenState.reducer
  ),
  // accessTokenState: AccessTokenState.reducer,
  regionState: persistReducer({ key: "region", storage }, RegionState.reducer),
  customerState: persistReducer(
    { key: "customer", storage },
    CustomerState.reducer
  ),
  cartState: CartState.reducer,
  cartNotificationState: CartNotificationState.reducer,
  checkoutState: CheckoutState.reducer,
  orderState: OrderState.reducer,
  adyenState: AdyenState.reducer,
  borgunState: BorgunState.reducer,
  netgiroState: NetgiroState.reducer,
  paymentState: PaymentProviderState.reducer,
  informationPageState: InformationPageState.reducer,
  errorMessageState: ErrorState.reducer,
  layoutState: LayoutState.reducer,
  giftCardState: GiftCardState.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer
})

export type RootState = ReturnType<typeof appReducer>
export type ReduxStore = EnhancedStore<RootState, UnknownAction>

export const useRootSelector = <T>(fn: (state: RootState) => T): T =>
  useSelector((state: RootState) => fn(state))

const RESET_STATE: UnknownAction = { type: "RESET_STATE" }

const listenerMiddleware = createListenerMiddleware()
listenerMiddleware.startListening({
  actionCreator: CartState.actions.setCart,
  effect: action => {
    const { cartState: state, id } = action.payload
    if (state === "Ordered") {
      logger.info("Cart has been ordered")
      CartId.clear()
    } else {
      CartId.set(id)

      // localStorage.removeItem("persist:order")
      // localStorage.removeItem("persist:payment")
      // localStorage.removeItem("persist:cart")
    }
  }
})

export const makeStore = () => {
  return configureStore<
    RootState,
    UnknownAction,
    Tuple<
      [
        ListenerMiddleware<
          RootState,
          ThunkDispatch<RootState, unknown, UnknownAction>
        >,
        ThunkMiddleware<RootState, UnknownAction>,
        Middleware
      ]
    >
  >({
    reducer: (state, action) => {
      if (action.type === RESET_STATE.type) {
        return appReducer(undefined, action)
      } else {
        return appReducer(state, action)
      }
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      })
        .prepend(listenerMiddleware.middleware)
        .concat(apiSlice.middleware),
    devTools: AppConfig.isDevMode
  })
}

export const State = makeStore()
export const persistor = persistStore(State)

export type AppDispatch = typeof State.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppStore = () => useStore<RootState>()

export default State
