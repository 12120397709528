import { createFlags, StaticBackend } from "flag"
import _cloneDeep from "lodash/cloneDeep"
import { GetServerSidePropsContext } from "next"
import { useRouter } from "next/router"
import { ReactNode, FC } from "react"
import { AppConfig } from "./Config"
import { IS } from "./i18n/Region"
import { useStoreContext } from "./i18n/StoreHooks"
import { ifBrowser, isBrowser } from "./utils/Browser"

export type FeatureFlagsI = {
  features: {
    sample: boolean
    applePay: boolean
    adyen: boolean
    disablePrismicNav: boolean
    vatId: boolean
  }
}

export const FeatureFlags: FeatureFlagsI = {
  features: {
    sample: AppConfig.featureFlags.sample,
    applePay: AppConfig.featureFlags.applePay,
    adyen: AppConfig.featureFlags.adyen,
    disablePrismicNav: AppConfig.featureFlags.disablePrismicNav,
    vatId: AppConfig.featureFlags.vatId
  }
}

const {
  FlagBackendProvider,
  Flag,
  useFlag: useFlagHook
} = createFlags<FeatureFlagsI>()

export const useFlag = useFlagHook
export const FlagProvider: FC<{
  children?: ReactNode
  flags: FeatureFlagsI
}> = ({ children, flags }) => {
  return (
    <FlagBackendProvider backend={new StaticBackend(flags)}>
      {children}
    </FlagBackendProvider>
  )
}
export default {
  useFlag,
  Flag,
  FlagProvider
}

export const getActiveFeatureFlagsFromContext = (
  context: GetServerSidePropsContext
): FeatureFlagsI => {
  return _cloneDeep(FeatureFlags)
}

export const useSampleFlag = (): boolean => {
  let sampleFlag = false

  ifBrowser(() => {
    const urlParams = new URLSearchParams(window.location.search)
    sampleFlag = urlParams.has("sample")
  })

  return useFlag("features.sample", sampleFlag)
}

export const usePayPal = (): boolean => {
  const store = useStoreContext()
  return !isIcelandicStore(store)
}

const applePayIsAvailable = (): boolean => {
  return (
    isBrowser() &&
    window["ApplePaySession"] &&
    window["ApplePaySession"]["canMakePaymentsWithActiveCard"] &&
    window["ApplePaySession"]["canMakePaymentsWithActiveCard"](
      "merchant.is.23west.dev"
    )
  )
}

export const useApplePay = (): boolean => {
  const router = useRouter()
  const store = useStoreContext()
  const featureFlag = useFlag("features.applePay", true)
  const urlFlag = router.query["useApplePay"] === "true"
  return (
    (featureFlag || urlFlag) &&
    applePayIsAvailable() &&
    !isIcelandicStore(store)
  )
}

export const isIcelandicStore = (store): boolean => store.country === IS.country

export const useVatId = (): boolean => {
  return useFlag("features.vatId", true)
}

export const useAdyen = (): boolean => {
  return useFlag("features.adyen", true)
}
