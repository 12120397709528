import { Country, Language } from "@sixty-six-north/i18n"
import { Currency } from "@sixty-six-north/i18n"
import { TypedMoney } from "@commercetools/platform-sdk"
import { useContext } from "react"
import { CentAmount } from "../cart/CartCalculator"
import { Store } from "../cart/Stores"
import { LinkBuilder } from "../components/Links"
import { useRootSelector } from "../State"
import {
  amountByCurrencyForApplePay,
  formatCurrency
} from "./CurrencyFormatter"
import {
  DEFAULT_REGION,
  EU,
  getSupportedCountriesForRegion,
  IS,
  Region,
  CountryWithCodeI,
  UK,
  DK
} from "./Region"
import {
  DefaultRegionContext,
  StoreFromCookieContext,
  StoreFromGeographyContext
} from "./StoreProvider"

const useRegion = () => {
  const regionFromRootSelector = useRootSelector(it => it.regionState).region
  const regionFromContext = useContext(DefaultRegionContext)

  return regionFromRootSelector || regionFromContext || DEFAULT_REGION
}

export const numberOfItemsStringFormat = (value: number): string =>
  String(value || "").padStart(1, "0")

export const usePreferredLanguage = () => {
  const region = useRegion()
  return region.language
}

const getFreeShippingThreshold = (country: Country): string => {
  switch (country) {
    case Country.dk:
      return "over 1.000 DKK"
    case Country.eu:
      return "over €250"
    case Country.is:
      return "20.000 ISK"
    case Country.gb:
      return "over £150"
    case Country.us:
      return "over $350"
  }
}

export class RegionApi {
  private readonly region?: Region

  constructor(region?: Region) {
    this.region = region
  }

  public isShoppingInIceland = (): boolean =>
    this.region?.country === IS.country

  public get isShoppingInUnitedKingdom() {
    return this.region?.country === UK.country
  }

  public get isShoppingInDenmark() {
    return this.region?.country === DK.country
  }
  public isShoppingInternationally = (): boolean =>
    this.region?.country !== IS.country
  public isShoppingInEurope = (): boolean => this.region?.country === EU.country
  public supportedCountries = () => getSupportedCountriesForRegion(this.region)
  public formatTypedMoney = (value: TypedMoney): string => {
    return formatCurrency(value.currencyCode as Currency)(value)
  }
}

export const useStoreContext = (): {
  country: Country
  currency: Currency
  language: Language
  formatCurrency: (amount: CentAmount) => string
  formatTypedMoney: (amount: TypedMoney) => string
  amountByCurrencyForApplePay: (amount: CentAmount) => string
  freeShippingThreshold: string
  isShoppingInIceland: () => boolean
  isShoppingInEurope: () => boolean
  isShoppingInternationally: () => boolean
  isShoppingInUnitedKingdom: boolean
  isShoppingInDenmark: boolean
  supportedCountries: () => CountryWithCodeI[]
} => {
  const region = useRegion()
  const regionApi = new RegionApi(region)
  return {
    ...regionApi,
    country: region.country,
    currency: region.currency,
    language: region.language,
    formatCurrency: formatCurrency(region.currency),
    isShoppingInUnitedKingdom: regionApi.isShoppingInUnitedKingdom,
    isShoppingInDenmark: regionApi.isShoppingInDenmark,
    amountByCurrencyForApplePay: amountByCurrencyForApplePay(region.currency),
    freeShippingThreshold: getFreeShippingThreshold(region.country)
  }
}

export const useLocaleAwareLinks = (): LinkBuilder => {
  const { country } = useStoreContext()

  return new LinkBuilder(country)
}

export const useStoreFromCookie = (): Store | undefined => {
  return useContext(StoreFromCookieContext)
}

export const useStoreFromGeography = (): Store | undefined => {
  return useContext(StoreFromGeographyContext)
}
