import { TokenInfo } from "@commercetools/sdk-auth"
import { UnknownAction } from "redux"
import { AccessTokenState } from "../auth/AccessTokenState"
import { AppConfig } from "../Config"
import { createLogger } from "../utils/createLogger"

export type TokenInfoSource = () => TokenInfo | undefined

export type WithAuth = (
  currentTokenInfo: TokenInfoSource,
  dispatch: (action: UnknownAction) => void,
  date?: Date
) => <T>(callback: () => Promise<T>) => Promise<T>

export const getTokenAnonymousFlow: () => Promise<TokenInfo> = async () => {
  const response = await fetch(`${AppConfig.appRoot}/api/token/anonymous`)
  return response.json()
}

export const executeTokenRefresh: (
  refreshToken: string
) => Promise<TokenInfo> = async (refreshToken: string) => {
  const response = await fetch(`${AppConfig.appRoot}/api/token/refresh`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      refreshToken
    })
  })
  return response.json()
}

export const withAuth: WithAuth =
  (
    currentTokenInfo: TokenInfoSource,
    dispatch: (action: UnknownAction) => void,
    date = new Date()
  ) =>
  async callback => {
    const logger = createLogger("withAuth")

    const token = currentTokenInfo()
    if (token?.expires_at && token?.expires_at > date.getTime()) {
      return callback()
    }

    const extra = {
      level: "info"
    }

    if (token?.refresh_token) {
      logger.info("Refresh token available, trying refresh")

      try {
        const refreshTokenResponse = await executeTokenRefresh(
          token.refresh_token
        )

        dispatch(
          AccessTokenState.actions.setToken({
            ...refreshTokenResponse,
            refresh_token: token.refresh_token
          })
        )

        return callback()
      } catch (error) {
        logger.error(
          "Failed refreshing token. Will create a new anonymous token.",
          error
        )
      }
    }

    logger.info("Creating new anonymous token")

    const tokenResponse = await getTokenAnonymousFlow()
    dispatch(AccessTokenState.actions.setToken(tokenResponse))

    return callback()
  }
