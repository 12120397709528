//jsxhook

import React, { ReactNode } from "react"
import { DomainCategory } from "../product/models/DomainCategory"
import { CategoryProps } from "../routing/CategoryPageParams"
import { ProductProps } from "../routing/ProductPageParams"
import { SubCategoryProps } from "../routing/SubCategoryPageParams"

export const RootCategoriesContext = React.createContext<DomainCategory[]>([])

export const CurrentCategoryContext = React.createContext<
  DomainCategory | undefined
>(undefined)

export const AllCategoriesMapContext = React.createContext<
  Record<string, DomainCategory>
>({})

export const SubCategoryContext = React.createContext<
  DomainCategory | undefined
>(undefined)

export const CategoryContext = React.createContext<DomainCategory | undefined>(
  undefined
)

export const VariantFilterContext = React.createContext<string | undefined>(
  undefined
)

export const CategoryProvider: React.FC<{
  categories?: DomainCategory[]
  category?: DomainCategory
  subCategory?: DomainCategory
  categoryPage?: CategoryProps
  subCategoryPage?: SubCategoryProps
  productPage?: ProductProps
  children?: ReactNode
}> = ({
  children,
  categories = [],
  subCategoryPage,
  categoryPage,
  productPage
}) => {
  const allCategories = categories || []
  const rootCategories = allCategories.filter(it => it.ancestors.length === 0)

  const category =
    productPage?.category ||
    subCategoryPage?.category ||
    categoryPage?.category ||
    undefined

  const subCategory =
    // productPage?.subCategory ||
    subCategoryPage?.subCategory

  const variantFilter =
    productPage?.params.variantFilter ||
    categoryPage?.params.variantFilter ||
    undefined

  const allCategoriesMap = allCategories.reduce(
    (previousValue, currentValue) => {
      return { ...previousValue, [currentValue.key]: currentValue }
    },
    {} as Record<string, DomainCategory>
  )

  const currentCategory = subCategory || category

  return (
    <AllCategoriesMapContext.Provider value={allCategoriesMap}>
      <RootCategoriesContext.Provider value={rootCategories}>
        <CurrentCategoryContext.Provider value={currentCategory}>
          <CategoryContext.Provider value={category}>
            <SubCategoryContext.Provider value={subCategory}>
              <VariantFilterContext.Provider value={variantFilter}>
                {children}
              </VariantFilterContext.Provider>
            </SubCategoryContext.Provider>
          </CategoryContext.Provider>
        </CurrentCategoryContext.Provider>
      </RootCategoriesContext.Provider>
    </AllCategoriesMapContext.Provider>
  )
}
