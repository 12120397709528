import { AppConfig } from "../Config"

export interface Config {
  projectKey: string
  credentials: {
    clientId: string
    clientSecret: string
  }
  scopes: string[]
  apiUrl: string
  authUrl: string
}

export const FrontendConfig: Config = AppConfig.commerceTools

export default FrontendConfig
