import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface NetgiroStateI {
  signature?: string
  totalAmount?: number
  referenceNumber?: string
  applicationId?: string
  redirectUrl?: string
}

export const NetgiroState = createSlice({
  name: "netgiro",
  initialState: {} as NetgiroStateI,
  reducers: {
    setPaymentState(state, action: PayloadAction<NetgiroStateI>) {
      return { ...state, ...action.payload }
    },
    clearPaymentState(state) {
      return {}
    },
    setRedirectUrl(state, action: PayloadAction<string>) {
      return { ...state, redirectUrl: action.payload }
    }
  }
})
