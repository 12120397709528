import { v4 } from "uuid"
import { isDevMode } from "../Config"

export const createLogger = (name: string) => {
  const uuid = v4().slice(0, 4)

  const message =
    (logger: (messageText?: unknown, ...optionalParams: unknown[]) => void) =>
    (messageText: string, args?: unknown) => {
      if (isDevMode) {
        if (!!args) {
          logger(`**** [${uuid} - ${name}] ${messageText}`, args)
        } else {
          logger(`**** [${uuid} - ${name}] ${messageText}`)
        }
      }
    }

  const { log, debug, info, warn, error } = console
  return {
    log: message(log),
    debug: message(debug),
    info: message(info),
    warn: message(warn),
    error: message(error)
  }
}
