//jsxhook

import { useUrlSearch } from "./UrlSearchHooks"
import { LocalizedString } from "@commercetools/platform-sdk"
import { BestFor, ProductQuery, SuitableFor } from "../product/ProductDal"

interface Tag {
  name: LocalizedString
  slug: LocalizedString
  filterModification: (pq: ProductQuery) => ProductQuery
}

const everyday: Tag = {
  name: {
    en: "Everyday",
    is: "Hversdags"
  },
  slug: {
    en: "everyday",
    is: "hversdags"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Everyday)
}
const running: Tag = {
  name: {
    en: "Running",
    is: "Hlaup"
  },
  slug: {
    en: "running",
    is: "hlaup"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Running)
}

const skiing: Tag = {
  name: {
    en: "Skiing",
    is: "Skíði"
  },
  slug: {
    en: "skiing",
    is: "skidi"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Skiing)
}
const hiking: Tag = {
  name: {
    en: "Hiking",
    is: "Göngur"
  },
  slug: {
    en: "hiking",
    is: "gongur"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Hiking)
}
const swimming: Tag = {
  name: {
    en: "Swimming",
    is: "Sund"
  },
  slug: {
    en: "swimming",
    is: "sund"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.Swimming)
}
const visitingIceland: Tag = {
  name: {
    en: "Visiting Iceland",
    is: "Heimsækja Ísland"
  },
  slug: {
    en: "visiting-iceland",
    is: "heimsaekja-island"
  },
  filterModification: it => it.withBestFor(BestFor.VisitingIceland)
}
const jumpingInPuddles: Tag = {
  name: {
    en: "Jumping in puddles",
    is: "Hoppa í polla"
  },
  slug: {
    en: "jumping-in-puddles",
    is: "hoppa-i-polla"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.JumpingInPuddles)
}
const playingInTheSnow: Tag = {
  name: {
    en: "Playing in the snow",
    is: "Sleðafjör"
  },
  slug: {
    en: "playing-in-snow",
    is: "sledafjor"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.PlayingInSnow)
}
const outdoorActivities: Tag = {
  name: {
    en: "Outdoor Activities",
    is: "Út að leika"
  },
  slug: {
    en: "outdoor-activities",
    is: "ut-ad-leika"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.OutdoorActivities)
}
const sleepingInTheStroller: Tag = {
  name: {
    en: "Sleeping in the stroller",
    is: "Í værum blundi í vagninum"
  },
  slug: {
    en: "sleeping-in-stroller",
    is: "blundar-i-vagninum"
  },
  filterModification: it => it.withSuitableFor(SuitableFor.SleepingInStroller)
}
const newArrivals: Tag = {
  name: {
    en: "New Arrivals",
    is: "Nýjar vörur"
  },
  slug: {
    en: "new-arrivals",
    is: "nyjar-vorur"
  },
  filterModification: it => it.newIn()
}

const festivalEssentials: Tag = {
  name: {
    en: "Festival Essentials",
    is: "Fatnaður fyrir íslenskt sumar"
  },
  slug: {
    en: "festival-essentials",
    is: "fatnadur-fyrir-islenskt-sumar"
  },
  filterModification: it =>
    it.withProductColorways(...festivalEssentialColorways)
}

const outlet: Tag = {
  name: {
    en: "Sale",
    is: "Útsölumarkaður"
  },
  slug: {
    en: "sale",
    is: "utsolumarkadur"
  },
  filterModification: it => it.onSale()
}

const allProducts: Tag = {
  name: {
    en: "All Products",
    is: "Allar vörur"
  },
  slug: {
    en: "all-products",
    is: "allar-vorur"
  },
  filterModification: it => it.removeCategoryFilter()
}

const tags: Tag[] = [
  everyday,
  running,
  skiing,
  hiking,
  swimming,
  visitingIceland,
  jumpingInPuddles,
  playingInTheSnow,
  outdoorActivities,
  sleepingInTheStroller,
  newArrivals,
  festivalEssentials,
  // privateSale,
  outlet,
  allProducts
]

export const TAG_FILTER_CONFIG: Record<string, Tag> = tags.reduce(
  (previousValue, currentValue) => {
    return {
      ...previousValue,
      [currentValue.slug.en]: currentValue,
      [currentValue.slug.is]: currentValue
    }
  },
  {}
)

export function onDynamicCategory<T>(
  categorySlug: string | null,
  callback: (dynamicCategory: string, tag: Tag) => T
) {
  const dynamicCategorySlugs = Object.keys(TAG_FILTER_CONFIG)
  const dynamicCategory = dynamicCategorySlugs.find(slug =>
    categorySlug?.endsWith(slug)
  )
  if (!dynamicCategory) return undefined
  return callback(dynamicCategory, TAG_FILTER_CONFIG[dynamicCategory])
}

export const getCommerceToolsFilterForSlug = (
  slug?: string
): ((plq: ProductQuery) => ProductQuery) => {
  const noModification: (plq: ProductQuery) => ProductQuery = q => q
  return (slug && TAG_FILTER_CONFIG[slug]?.filterModification) || noModification
}
export const useTagFilter = () => useUrlSearch("tag")

const festivalEssentialColorways: string[] = [
  "U99197-001",
  "V66149-100",
  "V66037-100",
  "W11268-103",
  "K11078-109",
  "V66188-109",
  "V66170-115",
  "V66109-115",
  "V66099-115",
  "K41141-132",
  "K41154-132",
  "V66036-147",
  "U99164-167",
  "U99211-167",
  "W41243-192",
  "W11322-192",
  "W11320-192",
  "W11258-192",
  "V66113-194",
  "V66033-195",
  "W41204-221",
  "W11205-221",
  "U99164-222",
  "U99133-222",
  "U91256-223",
  "W11265-224",
  "L81102-224",
  "W61221-224",
  "V66113-224",
  "W61222-224",
  "Q01246-224",
  "W11266-224",
  "Q01245-224",
  "U99164-231",
  "W11264-236",
  "W11263-236",
  "W11816-244",
  "W11783-244",
  "L81175-245",
  "L81279-245",
  "L81228-245",
  "W61384-245",
  "W11384-245",
  "U99164-290",
  "W11145-290",
  "K11780-291",
  "K11782-291",
  "V66182-291",
  "L86272-291",
  "K61725-291",
  "Q01199-292",
  "Q01100-292",
  "U99164-296",
  "U99197-296",
  "L81175-296",
  "L81218-296",
  "L81718-296",
  "K01199-296",
  "W41614-296",
  "W41613-296",
  "C11746-296",
  "C11745-296",
  "W11396-297",
  "W11247-298",
  "L81104-298",
  "W61246-298",
  "W11248-298",
  "K01250-298",
  "W61245-298",
  "W41249-298",
  "K01251-298",
  "Q51140-299",
  "W41259-299",
  "K11780-299",
  "W11174-299",
  "Q41139-299",
  "K61725-299",
  "K11782-299",
  "W11259-299",
  "L81288-308",
  "L81283-308",
  "L86184-308",
  "W11380-308",
  "W11381-308",
  "L81274-335",
  "W61386-380",
  "K01249-380",
  "W11377-380",
  "U99164-380",
  "U99211-380",
  "U99133-380",
  "L81232-380",
  "W11378-380",
  "Q01206-380",
  "W11379-380",
  "V66109-380",
  "V66033-401",
  "L81157-402",
  "Q01170-402",
  "Q01206-402",
  "Q01246-429",
  "W61384-429",
  "Q01245-429",
  "Q01243-429",
  "Q01244-429",
  "W11142-438",
  "V66188-441",
  "K11078-441",
  "K11076-447",
  "U99164-450",
  "U99164-455",
  "U99163-455",
  "U99133-455",
  "L81473-456",
  "L81468-463",
  "U99163-468",
  "K11194-469",
  "K11195-469",
  "L81157-469",
  "U99197-470",
  "W11264-472",
  "W11263-472",
  "W11743-494",
  "W61768-494",
  "L81292-494",
  "W11742-494",
  "W11314-499",
  "E11451-519",
  "L81274-519",
  "L81792-519",
  "L81794-519",
  "L81793-519",
  "K11194-519",
  "K11195-519",
  "L81216-531",
  "W11279-531",
  "W11816-534",
  "Q51140-536",
  "Q41156-536",
  "W41613-551",
  "W41165-551",
  "W11325-551",
  "W11324-551",
  "W11269-551",
  "W11165-571",
  "L81217-574",
  "L81229-574",
  "L86115-574",
  "L81468-574",
  "W11257-574",
  "W11832-575",
  "W11328-575",
  "K01141-575",
  "K11072-575",
  "K11102-575",
  "K11065-575",
  "W11833-575",
  "W11811-582",
  "W11812-582",
  "W41613-584",
  "V66113-584",
  "U99163-665",
  "L81194-670",
  "W11314-671",
  "K11077-774",
  "K41154-791",
  "W11395-795",
  "W11264-795",
  "W11396-795",
  "Q01170-795",
  "W41263-795",
  "W11263-795",
  "W11267-799",
  "W11268-799",
  "W61286-804",
  "W11293-804",
  "V66099-804",
  "K11195-812",
  "W11255-817",
  "W11120-834",
  "W11122-834",
  "W61384-842",
  "W11266-842",
  "V66099-842",
  "K41828-842",
  "W11265-842",
  "V66109-842",
  "W11385-842",
  "W11384-842",
  "W11386-842",
  "L81279-842",
  "T88211-842",
  "W11802-842",
  "W61282-854",
  "Q01100-855",
  "K11229-870",
  "V66113-871",
  "W11295-900",
  "W11248-900",
  "W11247-900",
  "W11296-900",
  "W41249-900",
  "W41243-900",
  "W11322-900",
  "W61246-900",
  "K61245-900",
  "W11832-900",
  "W61245-900",
  "W11320-900",
  "W11395-900",
  "W61248-900",
  "W61247-900",
  "W11833-900",
  "Q01184-900",
  "K41131-900",
  "K01247-900",
  "Q01183-900",
  "K01141-900",
  "L87103-900",
  "K11102-900",
  "W41611-900",
  "W11803-900",
  "Q11172-900",
  "K11782-900",
  "W11174-900",
  "Q11173-900",
  "W11114-900",
  "W11799-900",
  "K11690-901",
  "W41140-908",
  "W11401-908",
  "W61769-909",
  "W11743-909",
  "W61768-909",
  "W11742-909",
  "W11329-922",
  "K41154-981",
  "W11802-981",
  "K11140-990",
  "K11780-A519",
  "K11782-A519",
  "U99164-B493",
  "W11296-B673",
  "K61245-C192",
  "K01247-C192",
  "U99164-C470",
  "K01183-C810",
  "K01261-C900",
  "K11782-N132",
  "K61725-N132",
  "K41150-N441",
  "K11169-N441",
  "K11167-N441",
  "U99163-N665",
  "K61723-N900",
  "K11169-N900",
  "W61434-R900",
  "W61439-R900"
]
