import SdkAuth from "@commercetools/sdk-auth"
import { createClient } from "@commercetools/sdk-client-v2"
import { createAuthMiddlewareForClientCredentialsFlow } from "@commercetools/sdk-middleware-auth"
import { createHttpMiddleware } from "@commercetools/sdk-middleware-http"
import { createQueueMiddleware } from "@commercetools/sdk-middleware-queue"
import { createApiBuilderFromCtpClient } from "@commercetools/platform-sdk"
import fetch from "isomorphic-unfetch"

import FrontendConfig from "./Config"

const authMiddleware = createAuthMiddlewareForClientCredentialsFlow({
  ...FrontendConfig,
  host: FrontendConfig.authUrl
})

const httpMiddleware = createHttpMiddleware({
  host: FrontendConfig.apiUrl,
  timeout: 20000,
  //@ts-ignore
  getAbortController: () => new AbortController(),
  fetch
})

export const commerceToolsClient = createClient({
  middlewares: [
    authMiddleware,
    httpMiddleware,
    createQueueMiddleware({
      concurrency: 1
    })
  ]
})

export const commerceTools = createApiBuilderFromCtpClient(
  commerceToolsClient
).withProjectKey({
  projectKey: FrontendConfig.projectKey
})

export const commerceToolsAuth: SdkAuth = new SdkAuth({
  ...FrontendConfig,
  host: FrontendConfig.authUrl
})
