//jsxhook

import { usePreferredLanguage, useStoreContext } from "../i18n/StoreHooks"
import { ColorwayVariant } from "./models/DetailedProductInformation"
import { SelectSize } from "./product/SizeSelector"
import { ColorwayProxy } from "./ProductPage"
import { SKU } from "./VariantProxy"

export interface HasSize {
  size: string
}

enum Sizes {
  "extra-extra-extra-small" = 1000,
  "extra-extra-small",
  "extra-small",
  "extra-small-small",
  "small",
  "medium",
  "medium-large",
  "large",
  "extra-large",
  "double-extra-large",
  "triple-extra-large",
  "quadruple-extra-large",
  "quintuple-extra-large",
  "one-size",
  "35/36" = 35.5,
  "35-7" = 36,
  "38-0" = 39,
  "41-3" = 42,
  "44-6" = 45,
  "60 L" = 60,
  "90 L" = 90
}

const sizeAsNumeric = (obj: HasSize): number => {
  const { size } = obj
  return isNaN(+size) ? Sizes[size] : Number(size)
}

export const bySizeKeyOrder = (left: HasSize, right: HasSize): number =>
  sizeAsNumeric(left) - sizeAsNumeric(right)

export const useColorwaySizes = (
  currentSKU: SKU,
  currentColorway: ColorwayProxy,
  setVariant: (v: SKU) => void
): { allSizes: SelectSize[]; currentSize: SelectSize } => {
  const language = usePreferredLanguage()
  const currentVariant = currentColorway.variant(currentSKU)
  const currentSize: SelectSize = {
    label: currentVariant?.size?.label[language] || "",
    isInStock: currentVariant?.availability !== "out-of-stock"
  }

  const allSizes: ColorwayVariant[] = currentColorway.variants

  return {
    currentSize,
    allSizes: allSizes
      .map(it => ({
        label: it.size.label[language] || "",
        onSelect: () => setVariant(it.sku as SKU),
        isInStock: it.availability !== "out-of-stock",
        id: it.sku,
        size: it.size.key || ""
      }))
      .sort(bySizeKeyOrder)
  }
}
export const useStockInformation: () => boolean = () => {
  const storeContext = useStoreContext()
  return (
    storeContext.isShoppingInIceland() ||
    storeContext.isShoppingInUnitedKingdom ||
    storeContext.isShoppingInDenmark
  )
}
