import { NextRouter, Router, useRouter as useNextRouter } from "next/router"
import NProgress from "nprogress"
import { UrlObject } from "url"
import { ifBrowser } from "../utils/Browser"

NProgress.configure({ trickle: false, showSpinner: false })

Router?.events?.on("routeChangeStart", () => NProgress.start())
Router?.events?.on("routeChangeComplete", () => NProgress.done())
Router?.events?.on("routeChangeError", () => NProgress.done())

/**
 * Hook that wraps the router of next.js, always forcing a scroll to the  top
 */
export const useRouter = (): NextRouter => {
  const router = useNextRouter()

  return {
    ...router,
    push: (
      url: UrlObject | string,
      as?: UrlObject | string,
      options?: Record<string, unknown> | undefined
    ) =>
      router.push(url, as, options).then(res => {
        ifBrowser(() => window.scrollTo(0, 0))
        return res
      })
  }
}
