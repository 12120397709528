import { ValidationError } from "./ValidationError"

export class ValidationException extends Error {
  public validationError: ValidationError

  constructor(step: string, validationError: ValidationError) {
    super(`Failed validating checkout step: ${step}`)
    this.validationError = validationError
  }
}
