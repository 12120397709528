import { Cart } from "@commercetools/platform-sdk"
import { Option } from "funfix-core"
import { ifBrowser } from "./Browser"

export const getLocalStorageCart = (): Cart | undefined => {
  return ifBrowser(() =>
    Option.of(
      JSON.parse(
        JSON.parse(localStorage.getItem("persist:cart") || "{}")?.cart || "{}"
      )
    ).getOrElse(undefined)
  )
}
