import { Customer } from "@commercetools/platform-sdk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { CustomerStateI } from "./CustomerService"

const initialState: CustomerStateI = { loading: false }
export const CustomerState = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setCustomer(state: CustomerStateI, action: PayloadAction<Customer>) {
      return { ...state, customer: action.payload }
    },
    clearCustomer(state: CustomerStateI) {
      return { loading: state.loading }
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})
