import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useRootSelector } from "../../State"
import { GiftCard } from "./GiftCards"

export interface GiftCardStateI {
  token?: string
  card?: GiftCard
  isApplied?: boolean
}

export const GiftCardState = createSlice({
  name: "GiftCard",
  initialState: {} as GiftCardStateI,
  reducers: {
    setApplied(state, action: PayloadAction<boolean>) {
      return { ...state, isApplied: action.payload }
    },
    setCard(state, action: PayloadAction<GiftCard>) {
      return { ...state, card: action.payload }
    },
    setToken(state, action: PayloadAction<{ token: string }>) {
      return { ...state, token: action.payload.token }
    },
    markUsed(state) {
      return { ...state, used: true }
    },
    clearGiftCard(state) {
      return {}
    }
  }
})

export const useGiftCardState = () => useRootSelector(it => it.giftCardState)

export const useGiftCardToken = () =>
  useRootSelector(it => it.giftCardState.token)

export const useGiftCardData = () =>
  useRootSelector(it => it.giftCardState.card)
