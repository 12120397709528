import NodeCache from "node-cache"

export const cache = new NodeCache({
  stdTTL: 60,
  checkperiod: 30,
  deleteOnExpire: true,
  maxKeys: 1000
})

const cacheRequiresUpdate = (key: string): boolean => {
  return !cache.has(key)
}

const fetchCachedValue = <T>(key: string) => {
  return Promise.resolve(cache.get(key) as unknown as T)
}

const updateCache = <T>(key: string, timeout: number, fn: () => Promise<T>) => {
  if (cacheRequiresUpdate(key)) {
    return fn().then(value => {
      cache.set(key, value, timeout)
    })
  }
  return Promise.resolve()
}

export const memoizedFn = <T>(
  key: string,
  timeout: number,
  fn: () => Promise<T>
): Promise<T> => updateCache(key, timeout, fn).then(() => fetchCachedValue(key))

const seconds = (value: number): number => value
const minutes = (value: number): number => seconds(value * 60)

export const memoTimeout = {
  seconds,
  minutes
}
