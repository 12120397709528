import { createLoggerMiddleware as ctLogger } from "@commercetools/sdk-middleware-logger"
import { AppConfig } from "../Config"

export const noop = next => (request, response) => next(request, response)

export const createLoggerMiddleware = () => {
  if (AppConfig.isDevMode && AppConfig.commerceTools.useRequestLogger) {
    return ctLogger()
  } else {
    return noop
  }
}
