import { Either, isLeft, left, right } from "fp-ts/Either"
import { Monoid } from "fp-ts/Monoid"
import { ValidationError } from "./ValidationError"
import _uniq from "lodash/uniq"
import _keys from "lodash/keys"

export const ErrorMonoid: Monoid<ValidationError> = {
  concat(x: ValidationError, y: ValidationError): ValidationError {
    const keys = _uniq([..._keys(x), ..._keys(y)])
    return keys.reduce((accumulator, key) => {
      return {
        ...accumulator,
        [key]: [
          ...(accumulator[key] || []),
          ...(x[key] || []),
          ...(y[key] || [])
        ]
      }
    }, {} as ValidationError)
  },
  empty: {}
}
export const ValidationErrorMonoid = <T>(
  m: Monoid<T>
): Monoid<Either<ValidationError, T>> => {
  return {
    concat(
      x: Either<ValidationError, T>,
      y: Either<ValidationError, T>
    ): Either<ValidationError, T> {
      if (isLeft(x) && isLeft(y)) {
        return left(ErrorMonoid.concat(x.left, y.left))
      } else if (isLeft(x)) {
        return x
      } else if (isLeft(y)) {
        return y
      } else {
        return right(m.concat(x.right, y.right))
      }
    },
    empty: right(m.empty)
  }
}
export const VoidMonoid: Monoid<void> = {
  concat(x: void, y: void): void {
    return undefined
  },
  empty: undefined
}
