import {
  ProductProjection,
  ProductVariant,
  Attribute,
  LocalizedString,
  ChannelReference,
  AttributeLocalizedEnumValue
} from "@commercetools/platform-sdk"
import { ProductVariantAvailability } from "@commercetools/platform-sdk/dist/declarations/src/generated/models/product"
import { Store, Stores } from "../cart/Stores"
import { byOrderHint } from "../category/CategoryService"
import { filterTruthy, filterUndefinedKeys } from "../utils/Filter"
import { noop } from "../utils/FunctionUtilis"
import logger from "../utils/logger"
import { transformPricesForCurrency } from "../utils/ProductUtils"
import {
  DetailedProductInformation,
  Colorway,
  BasicColorway,
  BasicProductInformation,
  ColorwayAttribute,
  VariantAttribute,
  ProductAttribute,
  Facet,
  CoreProductInformation
} from "./models/DetailedProductInformation"
import { DomainCategory } from "./models/DomainCategory"
import {
  DomainCategoryMapping,
  ExpandCategoryReference,
  VARIANT_IS_AVAILABLE
} from "./ProductDal"
import {
  transformRecommendedProductsCollection,
  attributesToRecommendations2,
  IsAvailableIn
} from "./Recommendations"
import {
  Size,
  KeyAndLabel,
  GarmentComposition,
  marshalledModelDetail,
  ModelDetailAttribute,
  skuComponents,
  SKU,
  variantAssets
} from "./VariantProxy"

export const localizedStringLookup: (
  language: string,
  defaultTranslation?: string
) => (localizedString?: LocalizedString) => string = (
  language: string,
  defaultTranslation = ""
) => {
  return (localizedString?: LocalizedString) => {
    return localizedString?.[language] || defaultTranslation
  }
}

export function toAttributeMap(
  variant: ProductVariant | undefined
): Record<string, unknown> {
  return (variant?.attributes || []).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.name]: currentValue.value
    }),
    {}
  )
}

export interface ProductMappingStrategy<
  T extends BasicColorway,
  P extends BasicProductInformation<T>
> {
  addVariant(
    colorways: Record<string, T>,
    variant: ProductVariant,
    collector: (attributes: Record<string, unknown>) => void
  ): void

  generateProduct(productProjection: ProductProjection): P
}

const keyAndLabelToKey: (value: KeyAndLabel | unknown) => string = (
  value: KeyAndLabel | unknown
) => {
  return (value as KeyAndLabel)?.key || ""
}

export type ColorwayAvailability = "available" | "sale" | "planning-and-design"
export type ProductVariantsObserver = (p: ProductVariant[]) => void

interface MappingOptions {
  productVariantsObserver?: ProductVariantsObserver
  domainCategoryMapping?: DomainCategoryMapping
  availabilities?: ColorwayAvailability[]
}

export type StockLevel =
  | "in-stock"
  | "low-stock"
  | "two-left"
  | "one-left"
  | "out-of-stock"

export type RetailOption = "back-in-stock-notification-enabled"

const optimisedAvailability = (
  availability: ProductVariantAvailability | undefined,
  sku: string = ""
): StockLevel => {
  const availabilities = Object.values(availability?.channels || {})
  const isAvailable = availabilities.find(it => !!it.isOnStock) !== undefined
  const totalAvailable = availabilities.reduce(
    (previous: number, it) => (it.availableQuantity || 0) + previous,
    0
  )
  const stockLevel: StockLevel =
    totalAvailable === 0
      ? "out-of-stock"
      : totalAvailable === 1
        ? "one-left"
        : totalAvailable === 2
          ? "two-left"
          : totalAvailable < 4
            ? "low-stock"
            : "in-stock"
  if (
    (isAvailable && stockLevel === "out-of-stock") ||
    (!isAvailable && stockLevel !== "out-of-stock")
  ) {
    logger.warn(
      `Something weird is happening with stock levels for: ${sku} | ${isAvailable} | ${stockLevel}`
    )
  }

  return stockLevel
}
export const simpleMappingStrategy = (
  language: string,
  currency: string,
  {
    productVariantsObserver = noop,
    domainCategoryMapping = new ExpandCategoryReference(),
    availabilities = [VARIANT_IS_AVAILABLE]
  }: MappingOptions = {}
) => {
  const localizeString = localizedStringLookup(language || "en")

  const addVariant = (
    colorways: Record<string, BasicColorway>,
    variant: ProductVariant,
    collector: (attributes: Record<string, unknown>) => void = () => {
      return
    }
  ) => {
    const { prices } = variant
    const variantAttributes = toAttributeMap(variant)
    const {
      [ColorwayAttribute.colorCode]: color,
      [ColorwayAttribute.colorTerm]: colorTerm,
      [ColorwayAttribute.colorName]: colorName,
      [ColorwayAttribute.colorHexcode]: colorHexcode,
      [ColorwayAttribute.variantAvailability]: variantAvailability,
      [ColorwayAttribute.salesChannel]: salesChannels
    } = variantAttributes
    const colorString = color as string
    const colorwayAvailability = keyAndLabelToKey(
      variantAvailability
    ) as ColorwayAvailability

    if (!availabilities.includes(colorwayAvailability)) {
      return
    }

    if (!colorways[colorString]) {
      if (!colorHexcode) {
        try {
          const { product } = skuComponents(variant?.sku as SKU)
          logger.warn(
            `${product}-${color} does not have a colorHexcode: ${colorHexcode}`
          )
        } catch (error) {
          logger.error(error)
        }
      }
      colorways[colorString] = {
        color: colorString,
        name: localizeString(colorName as LocalizedString),
        term: keyAndLabelToKey(colorTerm),
        hexcode: (colorHexcode as string) || "",
        assets: variantAssets(variant),
        prices: transformPricesForCurrency(prices, currency),
        colorwayAvailability,
        salesChannels: (salesChannels as ChannelReference[]) || [],
        index: Object.keys(colorways).length,
        inStock: false
      }
    }
    const inStock = colorways[colorString].inStock
    colorways[colorString].inStock =
      inStock || optimisedAvailability(variant.availability) !== "out-of-stock"

    collector(variantAttributes)
  }
  return {
    addVariant,
    generateProduct(
      productProjection: ProductProjection
    ): CoreProductInformation {
      const {
        key,
        id,
        version,
        name,
        description,
        slug,
        metaTitle: seoTitle,
        metaDescription: seoDescription,
        masterVariant,
        variants,
        categories
      } = productProjection

      const colorways: Record<string, Colorway> = {}
      const prices = transformPricesForCurrency(masterVariant.prices, currency)
      const allVariants = [masterVariant, ...variants]
      const {
        [ProductAttribute.listingDescription]: listingDescription,
        [ProductAttribute.style]: style,
        [ProductAttribute.functionality]: functionality
      } = toAttributeMap(masterVariant)

      const sizes = new Set<string>()
      allVariants.forEach((variant: ProductVariant) => {
        addVariant(colorways, variant, ({ [VariantAttribute.size]: size }) => {
          const sizeKey = keyAndLabelToKey(size)
          if (sizeKey) sizes.add(sizeKey)
        })
      })
      const facets: Facet = {
        sizes: [...sizes],
        style: keyAndLabelToKey(style as KeyAndLabel[]),
        functionality: ((functionality || []) as KeyAndLabel[]).map(
          keyAndLabelToKey
        )
      }
      productVariantsObserver(allVariants)
      const c = (categories || [])
        .map(it => domainCategoryMapping.lookup(it))
        .filter(filterTruthy)
      return {
        key: key!,
        id,
        version,
        masterSku: productProjection.masterVariant.sku as SKU,
        name: localizeString(name),
        description: localizeString(description),
        slug,
        seoTitle: localizeString(seoTitle),
        seoDescription: localizeString(seoDescription),
        listingDescription: localizeString(
          listingDescription as LocalizedString
        ),
        categoryKeys: c.sort(byOrderHint).map(it => it.key),
        colorways,
        facets,
        prices
      }
    }
  }
}

export const basicMappingStrategy: (
  store: Store,
  mappingOptions?: MappingOptions
) => ProductMappingStrategy<BasicColorway, CoreProductInformation> = (
  store,
  mappingOptions = {}
) => {
  const { language, currency } = store || Stores.UK
  return simpleMappingStrategy(language, currency, mappingOptions)
}

export const fullMappingStrategy: (
  store: Store,
  category: DomainCategory | null,
  allCategories: DomainCategoryMapping,
  isAvailableIn: IsAvailableIn,
  availabilities?: ColorwayAvailability[]
) => ProductMappingStrategy<Colorway, DetailedProductInformation> = (
  store,
  category,
  allCategories,
  isAvailableIn,
  availabilities = [VARIANT_IS_AVAILABLE]
) => {
  const basic = basicMappingStrategy(store, {
    availabilities,
    domainCategoryMapping: allCategories
  })
  const localizeString = localizedStringLookup(store.language)
  const addVariant = (
    colorways: Record<string, Colorway>,
    variant: ProductVariant,
    collector: (attributes: Record<string, unknown>) => void = () => {
      return
    }
  ): void => {
    const {
      [VariantAttribute.size]: size,
      [ColorwayAttribute.colorCode]: color,
      [ColorwayAttribute.variantDiscount]: variantDiscount,
      [ColorwayAttribute.maleModel]: maleModel,
      [ColorwayAttribute.femaleModel]: femaleModel,
      [ColorwayAttribute.retailOptions]: retailOptions
    } = toAttributeMap(variant)
    const { availability, sku, isMatchingVariant } = variant
    const colorString = color as string
    let colorway: Colorway = colorways[colorString]
    if (!colorway) {
      basic.addVariant(colorways, variant, collector)
      colorway = colorways[colorString]
      if (!colorway) return
      colorways[colorString] = {
        ...colorway,
        ...filterUndefinedKeys({
          variantDiscount,
          enableBackInStockNotification: (
            (retailOptions as AttributeLocalizedEnumValue[]) || []
          )
            .map(it => it.key as RetailOption)
            .includes("back-in-stock-notification-enabled"),
          modelDetail: [
            marshalledModelDetail(
              (maleModel || []) as ModelDetailAttribute[],
              "maleModel"
            ),
            marshalledModelDetail(
              (femaleModel || []) as ModelDetailAttribute[],
              "femaleModel"
            )
          ]
            .filter(it => it.nonEmpty())
            .map(it => it.get()),
          variants: []
        })
      } as Colorway
    }
    colorways[colorString].variants.push({
      sku: sku as SKU,
      size: size as Size,
      availability: optimisedAvailability(availability),
      isMatchingVariant: isMatchingVariant || false
    })
  }

  const generateProduct = (
    productProjection: ProductProjection
  ): DetailedProductInformation => {
    const {
      [ProductAttribute.listingLabel]: listingLabel,
      [ProductAttribute.listingDescription]: listingDescription,
      [ProductAttribute.detailsShortDescription]: detailsShortDescription,
      [ProductAttribute.fit]: fit,
      [ProductAttribute.shell]: shell,
      [ProductAttribute.bestFor]: bestFor,
      [ProductAttribute.garmentComposition]: garmentComposition,
      [ProductAttribute.garmentType]: garmentType,
      [ProductAttribute.garmentSubtype]: garmentSubtype,
      [ProductAttribute.suitableFor]: suitableFor,
      [ProductAttribute.functionality]: functionality,
      [ProductAttribute.layering]: layering,
      [ProductAttribute.fitDescription]: fitDescription,
      [ProductAttribute.washingInstructions]: washingInstructions,
      [ProductAttribute.wearItWithRecommendations]: wearItWithRecommendations,
      [ProductAttribute.layerItWithRecommendations]: layerItWithRecommendations,
      [ProductAttribute.alternativeRecommendations]: alternativeRecommendations,
      [ProductAttribute.phoneticSpelling]: phoneticSpelling,
      [ProductAttribute.style]: style
    } = toAttributeMap(productProjection.masterVariant)

    const { masterVariant, variants } = productProjection

    const colorways: Record<string, Colorway> = {}
    const allVariants = [masterVariant, ...variants]
    allVariants.forEach((variant: ProductVariant) => {
      addVariant(colorways, variant)
    })

    const recommendations = transformRecommendedProductsCollection(
      [
        attributesToRecommendations2(
          "wiw",
          wearItWithRecommendations as Attribute[][],
          basic,
          store.language
        ),
        attributesToRecommendations2(
          "liw",
          layerItWithRecommendations as Attribute[][],
          basic,
          store.language
        ),
        attributesToRecommendations2(
          "alternatives",
          alternativeRecommendations as Attribute[][],
          basic,
          store.language
        )
      ].filter(it => it.recommendations.length > 0),
      store,
      category,
      allCategories,
      isAvailableIn
    )
    return {
      ...basic.generateProduct(productProjection),
      colorways,

      attributes: filterUndefinedKeys({
        listingLabel: localizeString(listingLabel as LocalizedString),
        listingDescription: localizeString(
          listingDescription as LocalizedString
        ),
        detailsShortDescription: localizeString(
          detailsShortDescription as LocalizedString
        ),
        fit,
        shell: shell as KeyAndLabel[],
        bestFor,
        garmentComposition: garmentComposition as GarmentComposition[][],
        garmentType,
        garmentSubtype,
        suitableFor: suitableFor as KeyAndLabel[],
        functionality: functionality as KeyAndLabel[],
        layering: layering as KeyAndLabel[],
        fitDescription: localizeString(fitDescription as LocalizedString),
        washingInstructions: (washingInstructions || []) as KeyAndLabel[],
        phoneticSpelling: phoneticSpelling as string | undefined,
        style: style as KeyAndLabel,
        recommendations: recommendations || []
      })
    }
  }

  return {
    addVariant,
    generateProduct
  }
}

export function transform<
  C extends BasicColorway,
  P extends BasicProductInformation<C>
>(
  productProjection: ProductProjection,
  mapping: ProductMappingStrategy<C, P>
): P {
  return filterUndefinedKeys(mapping.generateProduct(productProjection)) as P
}
