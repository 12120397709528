import { createSlice } from "@reduxjs/toolkit"
import { useRootSelector } from "../State"

export interface CheckoutStateI {
  showReviewPageOverview: boolean
}

const initialCheckoutStateI: CheckoutStateI = {
  showReviewPageOverview: true
}
export const CheckoutState = createSlice({
  name: "checkoutState",
  initialState: initialCheckoutStateI,
  reducers: {
    hideOverview(state) {
      return { ...state, showReviewPageOverview: false }
    },
    clear() {
      return initialCheckoutStateI
    }
  }
})

export const useCheckoutState: () => CheckoutStateI = () =>
  useRootSelector(it => it.checkoutState)
