import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface BorgunStateI {
  paymentToken?: string
}

export const BorgunState = createSlice({
  name: "borgun",
  initialState: {} as BorgunStateI,
  reducers: {
    setPaymentToken(state, action: PayloadAction<{ paymentToken: string }>) {
      return { ...state, ...action.payload }
    },
    clearPaymentToken(state) {
      return {}
    }
  }
})
