import _flatten from "lodash/flatten"
import _isArray from "lodash/isArray"
import {
  PrismicData,
  PrismicDocument,
  PrismicDocumentLink,
  PrismicItem,
  PrismicSliceType,
  PrismicPrimaryProps,
  PrismicSlice,
  PrismicText
} from "./PrismicModels"

/**
 * Given a Prismic document aggregates all Prismic UIDs
 */
export class PrismicIdAggregator {
  public aggregateMany(documents: PrismicDocument[]): string[] {
    const ids: string[] = []
    const aggregatedIds = documents.reduce((ids, document) => {
      return ids.concat(document ? this.aggregate(document) : [])
    }, ids)
    return [...new Set(aggregatedIds)]
  }

  public aggregate(document: PrismicDocument): string[] {
    const bodyIds = (document?.data?.body || []).reduce(this.reducer, [])
    const messageIds = this.extractIdsFromMessages(document.data || {})
    const dataIds = this.extractIdsFromData(document.data || {})

    return [...new Set([...bodyIds, ...messageIds, ...dataIds])]
  }

  public aggregateRelatedContentBlocks(documents: PrismicDocument[]): string[] {
    const reducer = (acc, document): string[] => {
      const relatedContentSliceIds: string[] =
        document.data?.body
          ?.filter(
            slice => slice.slice_type === PrismicSliceType.relatedContent
          )
          .map(slice => slice.primary.related_content_block?.id)
          .filter(i => i) || []
      return acc.concat(relatedContentSliceIds)
    }
    const aggregatedIds = documents.reduce(reducer, [])
    return [...new Set(aggregatedIds)]
  }

  private reducer = (accumulator: string[], value: PrismicSlice): string[] => {
    const ids = this.extractIdsFromSlice(value)
    return accumulator.concat(ids)
  }

  private extractIdsFromSlice = (value: PrismicSlice): string[] => {
    return this.extractIdsFromPrismicPrimary(value.primary).concat(
      this.extractIdsFromItems(value.items)
    )
  }

  private extractIdsFromMessages = (value: PrismicData): string[] => {
    return value.messages?.filter(it => it.link.id).map(it => it.link.id) || []
  }

  private extractIdsFromData = (value: PrismicData): string[] => {
    const items = [
      ...(value.navigation_items || []).map(it => it.main_url),
      ...(value.column_1 || []).map(it => it.prismic_link),
      ...(value.column_2 || []).map(it => it.prismic_link),
      ...(value.column_3 || []).map(it => it.prismic_link),
      ...(value.link ? [value.link] : [])
    ]

    return items
      .filter(notUndefined)
      .filter(it => it.link_type === "Document" && it.id)
      .map(it => it.id)
  }

  private isPrismicText = (obj: any): obj is PrismicText => {
    const keys = Object.keys(obj)
    return (
      keys.indexOf("type") > -1 &&
      keys.indexOf("text") > -1 &&
      keys.indexOf("spans") > -1
    )
  }

  private isPrismicLink = (obj: any): obj is PrismicDocumentLink => {
    const keys = Object.keys(obj)
    return (
      keys.indexOf("id") > -1 &&
      keys.indexOf("uid") > -1 &&
      keys.indexOf("slug") > -1 &&
      keys.indexOf("link_type") > -1
    )
  }

  private extractIdsFromPrismicPrimary(primary: PrismicPrimaryProps): string[] {
    const texts: PrismicText[] = _flatten(
      Object.values(primary).filter(it => _isArray(it))
    ).filter(this.isPrismicText)

    return texts.flatMap(this.extractIdsFromPrismicText)
  }

  private extractIdsFromPrismicText = (text: PrismicText): string[] => {
    return text.spans.map(it => it.data?.id).filter(notUndefined)
  }

  private extractIdsFromItems(items: PrismicItem[]): string[] {
    return items.flatMap(this.extractIdsFromPrismicItem)
  }

  private extractIdsFromPrismicItem = (item: PrismicItem): string[] => {
    const ids: string[] = []
    const links = [
      item.link,
      item.link_1,
      item.link_2,
      item.link_3,
      item.link_4,
      item.content
    ]

    links.forEach(it => {
      if (it && it.link_type === "Document" && it.id) {
        ids.push(it.id)
      }
    })

    return ids
  }
}

export const notUndefined = <T>(x: T | undefined | null): x is T => {
  return x !== undefined && x !== null
}
