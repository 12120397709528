import { RichTextBlock } from "prismic-reactjs"

/**
 * NOTE: This is duplicated from `core-ui`. Importing from core-ui fails the
 * tests
 */
export type WindowVariants =
  | "tower_block_left"
  | "tower_block_right"
  | "asymmetric_left"
  | "asymmetric_right"
  | "split_left"
  | "split_right"
  | "double"
  | "triple"
  | "quad"

/**
 * NOTE: This is duplicated from `core-ui`. Importing from core-ui fails the
 * tests
 */
export type PrismicRatios = "16:9" | "1:1" | "3:4" | "4:3" | "21:9" | "none"

export type PrismicDesignTokenColors =
  | "green.0"
  | "red.0"
  | "yellow.0"
  | "blue.0"
  | "grey.0"

export type PrismicSliceWidth = "full" | "standard" | "half_left" | "half_right"

export type PrismicTextAlignment = "center" | "right" | "left" | "column-2"

export type ProductAndAssetAlignment = "right" | "left"
export type PrismicTextStyle =
  | "editorial_large"
  | "technical_large"
  | "technical_small"
  | "brand_voice"
  | "deck"

export interface SimplePrismicDocument {
  id: string
  uid?: string
  alternate_languages: AlternateLanguage[]
  data?: PrismicData
}

export interface PrismicCheckoutConfiguration {
  disabled: boolean
  title?: string
  description?: string
}

export interface PrismicPronunciationDocument {
  id: string
  uid?: string
  alternate_languages?: AlternateLanguage[]
  data: {
    product_name?: string
    pronunciation?: string
    audio_file?: PrismicVideo
    description?: RichTextBlock[]
  }
}
export interface PrismicDocument {
  id: string
  uid: string
  type: string
  href: string
  tags: string[]
  slugs: string[]
  linked_documents?: string[]
  lang?: string
  alternate_languages: AlternateLanguage[]
  first_publication_date: string | null
  last_publication_date: string | null
  data?: PrismicData
}

export interface PrismicLinkData {
  path?: string | null
  display_url?: string | null
  title?: PrismicText[]
  meta_title?: PrismicText[] | null
  description?: PrismicText[]
  meta_description?: PrismicText[] | null
  meta_list_description?: PrismicText[] | null
  thumbnail?: PrismicImage | null
  article_thumbnail?: PrismicImage | null
  link_label?: string | null
  links?: { link: PrismicDocument }[]
}

export interface PrismicLinkedDocument {
  id: string
  uid?: string
  alternate_languages: AlternateLanguage[]
  lang?: string
  data?: PrismicLinkData
}

export interface AlternateLanguage {
  id: string
  uid?: string
  type: string
  lang: string
}

export interface PrismicColumnItem {
  label?: PrismicText[]
  prismic_link?: PrismicDocumentLink
  internal_url?: string
  commerce_tools_category?: PrismicCategoryLink
  commerce_tools_shelf_name?: string
  tag_filter?: string
}

export interface Tooltip {
  key: string
  description: { text: string }[]
  learn_more: { id: string; data: { path: string } }
}

interface PrismicMessageBarItem {
  link: PrismicLinkedDocument
  link_text: PrismicText[]
  title: PrismicText[]
}

export interface PrismicData {
  title?: PrismicText[]
  region?: string
  path?: string
  display_url?: string
  audio_file?: PrismicAudioFile
  additional_product_filters?:
    | {
        name: string
      }[]
    | null
  messages?: PrismicMessageBarItem[]
  checkout_messages?: {
    language: string
    title?: PrismicText[]
    description?: PrismicText[]
  }[]
  allowed_product_filters?: string[]
  meta_title?: PrismicText[]
  noindex_enabled?: boolean | null
  meta_description?: PrismicText[]
  meta_list_description?: PrismicText[]
  description?: PrismicText[]
  thumbnail?: PrismicImage
  article_thumbnail?: PrismicImage | null
  opengraph_image?: PrismicImage
  body?: PrismicSlice[]
  navigation_items?: PrismicItem[]
  column_1_title?: PrismicText[]
  column_2_title?: PrismicText[]
  column_3_title?: PrismicText[]
  column_4_title?: PrismicText[]
  column_5_title?: PrismicText[]
  tags_column_title?: PrismicText[]
  column_1?: PrismicColumnItem[]
  column_2?: PrismicColumnItem[]
  column_3?: PrismicColumnItem[]
  column_4?: PrismicColumnItem[]
  column_5?: PrismicColumnItem[]
  tags_column?: PrismicColumnItem[]
  image?: PrismicImage
  internal_advert_title?: PrismicText[]
  internal_advert_text?: PrismicText[]
  link?: PrismicDocumentLink
  links?: { link: PrismicDocument }[]
  link_label?: string
  date_posted?: string
  shelves?: PrismicShelf[]
  category?: PrismicCategoryLink
  product_variants_documents?: ProductVariantDocument[]
  product_articles?: {
    product_article: PrismicDocumentLink
  }[]
  variant_name?: PrismicText[]
  product_key_text?: string
  suitablefor?: { suitablefor_key: string }[]
  set_as_default_articles?: boolean
  tooltip?: Tooltip[]
  articles?: { article: PrismicDocument }[]
  disable_checkout?: boolean
}

export interface ProductVariantDocument {
  category: string
  color_code: string
  variant: PrismicDocumentLink
}

export interface PrismicDimensions {
  width: number
  height: number
}

export interface PrismicImage {
  url: string
  alt: string
  dimensions: PrismicDimensions
  mobile?: {
    url: string
    alt: string
    dimensions: PrismicDimensions
  }
}

export interface PrismicVideo {
  url: string
}

export interface ImgixVideo {
  name: string
  playback_url: string
}

export enum PrismicLinkType {
  document = "Document"
}

export enum PrismicSpanType {
  hyperlink = "hyperlink"
}

export interface PrismicSpanData {
  id?: string
  type?: string
  slug?: string
  link_type?: PrismicLinkType
}

export interface PrismicSpan {
  start?: number
  end?: number
  type?: PrismicSpanType
  data?: PrismicSpanData
}

export interface PrismicText {
  type: string
  text: string
  spans: PrismicSpan[]
}

export interface PrismicDocumentLink {
  id: string
  uid: string
  url?: string
  slug: string
  lang: string
  link_type: string
  tags: string[]
  target?: string
}

export interface PrismicAudioFile {
  kind?: string
  link_type?: string
  name?: string
  size?: string
  url: string
}

export interface PrismicCategoryLink {
  id: string
  key: string
  slug: {
    [key: string]: string
  }
}

export interface PrismicShelf {
  shelf_id: string
  shelf_name: PrismicText[]
  shelf_description: PrismicText[]
}

export interface PrismicCoordinates {
  latitude: number
  longitude: number
}

export interface PrismicCommerceToolsId {
  productCode: string
  colorCodes: string[]
}

export interface PrismicProductRecommendationDropDown {
  type: string
  text: string
}

export type CarouselStyle = "original" | "pdp" | "carousel"
export type CarouselPaginationType = "finite" | "infinite"

export type EmbedTypes = "YouTube" | "Spotify" | "Issuu"

export interface PrismicAudioTrack {
  audio_track?: string
  audio_artist?: string
  audio_file?: PrismicVideo
  audio_track_length?: string
}

export interface Segment {
  id: string
  listId: string
  type: string
  title: string
  description: string
}

export interface PrismicPrimaryProps {
  // TODO: Replace any types with actual definitions
  audio_tracks?: PrismicAudioTrack[]
  the_text?: RichTextBlock[]
  the_image?: PrismicImage
  image?: PrismicImage
  video?: PrismicVideo
  imgix_video?: ImgixVideo
  caption?: RichTextBlock[]
  statement?: PrismicText[]
  label?: PrismicText[]
  sentence?: PrismicText[]
  subtitle?: PrismicText[]
  section_title?: PrismicText[]
  banner_title?: PrismicText[]
  navigation_title?: PrismicText[]
  recommended_title?: PrismicText[]
  article_list_title?: PrismicText[]
  statistic_title?: PrismicText[]
  table_title?: PrismicText[]
  count_label?: PrismicText[]
  benefits_list?: PrismicText[]
  embed?: {
    provider_name: EmbedTypes
    html: string
    width?: number
    height?: number
  }
  title?: PrismicText[]
  aside: boolean
  color_background_override: string
  color_mode: "light" | "mid" | "dark"
  color_text_override: string
  columns: any
  display_as_gallery?: boolean
  hasgradientoverlay?: boolean
  header_distance: "none" | "with-navigation"
  layout: "equal" | "center"
  mark_as_h1?: boolean
  mobile_visual_rule: boolean
  padding_bottom: "0" | "24" | "32" | "64" | "128"
  padding_top: "0" | "24" | "32" | "64" | "128"
  quote_size?: "inline" | "pullout"
  ratio?: PrismicRatios
  card_ratio?: PrismicRatios
  space_between: "external" | "internal" | "internal-small" | "none"
  text_alignment?: PrismicTextAlignment
  text_style?: PrismicTextStyle
  width: PrismicSliceWidth
  window_variant?: WindowVariants
  use_rule?: boolean
  vertically_center?: boolean
  gpx_file?: PrismicDocumentLink
  align_links_right?: boolean
  product_recommendation_title?: PrismicText[]
  product_recommendation_subtitle?: PrismicText[]
  product_recommendation_attribute_key?: string
  product_recommendation_attribute_value?: string
  product_recommendation_section_id?: string
  product_recommendation_section?: string
  product_recommendation_variant_availability?: "available" | "lagersala"
  product_recommendation_section_style?: CarouselStyle
  commerce_tools_categories?: PrismicCategoryLink[]
  dynamic_category_modification?: string
  limit?: number
  dropdown_name?: PrismicProductRecommendationDropDown[]
  form?:
    | "contact-us"
    | "newsletter"
    | "newsletter-corporate-is"
    | "sponsorships"
  subscription_required?: boolean
  audience_id?: string
  segment_id?: string
  audience_tag?: Segment
  mandrill_template?: string
  address?: RichTextBlock[]
  contact?: RichTextBlock[]
  coordinates?: PrismicCoordinates
  directions?: RichTextBlock[]
  component?: "sizeguide"
  alignment?: ProductAndAssetAlignment
  date_label?: string
  competition_end_date?: string
  anchor_id?: string
  related_content_block?: PrismicDocumentLink
  pagination_type?: CarouselPaginationType
  auto_paginate_interval_in_seconds?: number
  animation_duration_in_seconds?: number
}

export interface PrismicItem {
  audio_track?: string
  audio_artist?: string
  audio_file?: PrismicVideo
  audio_track_length?: string
  section_title?: PrismicText[]
  card_title?: PrismicText[]
  card_text?: RichTextBlock[]
  image?: PrismicImage
  video?: PrismicVideo
  imgix_video?: ImgixVideo
  commerce_tools_category?: PrismicCategoryLink
  content?: PrismicDocumentLink
  credit_label?: PrismicText[]
  credit_value?: PrismicText[]
  label?: RichTextBlock[] | string
  link?: PrismicDocumentLink
  link_1?: PrismicDocumentLink
  link_2?: PrismicDocumentLink
  link_3?: PrismicDocumentLink
  link_4?: PrismicDocumentLink
  link_label?: PrismicText[]
  link_1_label?: PrismicText[]
  link_2_label?: PrismicText[]
  link_3_label?: PrismicText[]
  link_4_label?: PrismicText[]
  category_link_1?: PrismicCategoryLink
  category_link_2?: PrismicCategoryLink
  category_link_3?: PrismicCategoryLink
  category_link_4?: PrismicCategoryLink
  hasgradientoverlay?: boolean
  article?: any
  additional_label?: PrismicText[]
  stat?: PrismicText[]
  value?: RichTextBlock[]
  tags?: string
  coordinates?: PrismicCoordinates
  product_id?: PrismicCommerceToolsId
  product_id_2?: PrismicCommerceToolsId
  product_id_3?: PrismicCommerceToolsId
  product_id_4?: PrismicCommerceToolsId
  preferred_color_code?: string
  product_badge?: string
  anchor_id?: string
  tab?: string
  day?: PrismicText[]
  time?: PrismicText[]
  background_color?: PrismicDesignTokenColors
  product_category?: "mens" | "womens"
  main_url?: PrismicDocumentLink // used in navigation type
  question_title?: PrismicText[]
  question_text?: PrismicText[]
  year?: PrismicText[]
}

export enum PrismicSliceType {
  text = "text_slice",
  media = "media_slice",
  card = "card_slice",
  statementHero = "statement_hero_slice",
  titleSentenceHero = "title_sentence_hero_slice",
  banner = "banner_slice",
  competitionHeader = "competition_header",
  stickyHeader = "sticky_header",
  featureSlice = "feature",
  textListSlice = "text_list",
  standFirst = "standfirst_slice",
  window = "window_slice",
  embed = "embed_slice",
  horizontalRule = "horizontal_rule_slice",
  sectionTitle = "section_title",
  navigationBar = "navigation_bar_slice",
  productListCaption = "product_list_caption",
  recommendedArticle = "recommended_articles_slice",
  marketingChannelPromotion = "marketing_channel_promotion",
  articleList = "article_list_slice",
  relatedArticleList = "related_article_list",
  quote = "quote_slice",
  statistic = "statistic_slice",
  statisticGrid = "statistic_grid_slice",
  table = "tables_slice",
  googleMap = "map_slice",
  promotionCards = "promotions_slice",
  productRecommendationByAttribute = "product_recommendation_section_by_attribute",
  productRecommendationSectionByIds = "product_recommendation_section_by_ids",
  productRecommendationIds = "product_recommendation_ids",
  productsByCategory = "products_by_category",
  form = "forms_slice",
  mailingListForm = "mailing_list_forms_slice",
  store_details = "store_details",
  custom_component = "custom_component_slice",
  product_and_asset = "product_and_asset",
  audio_player = "audio_player",
  relatedContent = "related_content_block",
  tabContentViewer = "tab_content_viewer"
}

export interface PrismicSlice {
  id: string
  primary: PrismicPrimaryProps
  items: PrismicItem[]
  slice_type: PrismicSliceType
}
