//jsxhook

import React, { ReactNode, useContext } from "react"
import { DEFAULT_STORE } from "../cart/I18NStores"
import { Store } from "../cart/Stores"
import { StoreProps } from "../next/GlobalProps"
import { DEFAULT_REGION, Region } from "./Region"
import { Store as CommerceToolsStore } from "@commercetools/platform-sdk"
export const DefaultRegionContext = React.createContext<Region>(DEFAULT_REGION)

export const StoreFromCookieContext = React.createContext<Store | undefined>(
  undefined
)
export const CommerceToolsStoreContext = React.createContext<
  CommerceToolsStore | undefined
>(undefined)

export const useCommerceToolsStore = (): CommerceToolsStore | undefined => {
  return useContext(CommerceToolsStoreContext)
}

export const StoreFromGeographyContext =
  React.createContext<Store>(DEFAULT_STORE)

export const StoreProvider: React.FC<{
  children?: ReactNode
  region?: Region
  store: StoreProps
}> = ({ store, region, children }) => {
  if (region) {
    return (
      <DefaultRegionContext.Provider value={region}>
        <StoreFromCookieContext.Provider
          value={store.storeFromCookie || undefined}
        >
          <StoreFromGeographyContext.Provider
            value={store.storeFromGeography || DEFAULT_STORE}
          >
            <CommerceToolsStoreContext.Provider
              value={store.commerceToolsStore || undefined}
            >
              {children}
            </CommerceToolsStoreContext.Provider>
          </StoreFromGeographyContext.Provider>
        </StoreFromCookieContext.Provider>
      </DefaultRegionContext.Provider>
    )
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}
