import { Order } from "@commercetools/platform-sdk"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"

export interface OrderStateI {
  order?: Order
}

const initialState: OrderStateI = {}

export const OrderState = createSlice({
  name: "orderState",
  initialState,
  reducers: {
    setOrder(state: OrderStateI, action: PayloadAction<Order>) {
      return { ...state, order: action.payload }
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})
