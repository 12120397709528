import { Cart } from "@commercetools/platform-sdk"
import * as Sentry from "@sentry/browser"
import { Either, isRight, left } from "fp-ts/Either"
import { Monoid } from "fp-ts/Monoid"
import { ErrorMessage } from "../../error/ErrorState"
import { ErrorType } from "../../error/ErrorType"
import { createLogger } from "../../utils/createLogger"
import { ValidationException } from "./ValidationException"
import { ValidationErrorMonoid } from "./ValidationMonoids"

const logger = createLogger("ValidationError")

export interface ValidationError {
  [key: string]: { title?: string; message: string }[]
}

export type ValidationResult = Promise<Either<ValidationError, void>>

export type CartValidationResult = Promise<Either<ValidationError, Cart>>

export const simpleValidationError = (
  key: string,
  ...errors: string[]
): ValidationError => {
  return { [key]: errors.map(message => ({ message })) }
}

export const validationError = (
  key: string,
  message: { title?: string; message: string }
): ValidationError => {
  return { [key]: [message] }
}

export const validationErrorPromise = (error: ValidationError) =>
  Promise.resolve(left(error))

export const rejectValidationErrors =
  <T>(message: string) =>
  (result: Either<ValidationError, T>): Promise<T> => {
    if (isRight(result)) {
      return Promise.resolve(result.right)
    } else {
      return Promise.reject(new ValidationException(message, result.left))
    }
  }

export const reduceAndReject =
  <T>(monoid: Monoid<T>, step = "reduceAndReject") =>
  (results: Either<ValidationError, T>[]): Promise<T> => {
    return Promise.resolve(
      results.reduce(
        ValidationErrorMonoid(monoid).concat,
        ValidationErrorMonoid(monoid).empty
      )
    ).then(rejectValidationErrors(step))
  }
export const error = (
  errorType: ErrorType,
  message: ErrorMessage
): ValidationResult =>
  Promise.resolve(left(validationError(errorType, message)))

export const cartError = (
  errorType: ErrorType,
  message: ErrorMessage
): CartValidationResult =>
  Promise.resolve(left(validationError(errorType, message)))

export const captureSentryErrorsAndThrow =
  (message: string, cartId?: string) => (err: any) => {
    if (!(err instanceof ValidationException)) {
      logger.error(`${message}: cart=${cartId}`, err)
      Sentry.captureException(err, {
        extra: {
          commerceToolsCartId: cartId,
          data: JSON.stringify(err?.body || {})
        }
      })
    }
    throw err
  }
