import { useContext } from "react"
import { CommerceToolsClient } from "./CommerceToolsClient"
import { UserClientContext } from "./CommerceToolsClientProvider"

export const useCommerceToolsClient = (): CommerceToolsClient => {
  return (
    useContext<CommerceToolsClient>(UserClientContext) ||
    ({} as CommerceToolsClient)
  )
}
