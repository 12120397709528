export enum ErrorType {
  BillingAddressError = "BillingAddressError",
  AdditionalOrderInformationError = "AdditionalOrderInformationError",
  CartAlreadyOrderedError = "CartAlreadyOrderedError",
  CartNotFoundError = "CartNotFoundError",
  CartValidationError = "CartValidationError",
  CustomerInformationError = "CustomerInformationError",
  GiftCardError = "GiftCardError",
  OrderError = "OrderError",
  PasswordResetError = "PasswordResetError",
  PaymentError = "PaymentError",
  ShippingMethodError = "ShippingMethodError"
}
