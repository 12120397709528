import * as axios from "axios"

export const AxiosClient = () =>
  axios.default.create({
    // Only reject promise if status code is > 500
    validateStatus: it => it < 500
  })

export const axiosDefaultClient: axios.AxiosInstance = axios.default.create({
  // Only reject promise if status code is > 500
  validateStatus: it => it < 500
})

export const useAxiosClient = (): axios.AxiosInstance => axiosDefaultClient
