import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { StoreAbbreviation } from "../../../common/store"
import { MinimalShippingMethod } from "./route"

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
  endpoints: builder => ({
    getShippingMethods: builder.query<
      MinimalShippingMethod[],
      StoreAbbreviation
    >({
      query: store => `/${store}/shipping-methods`
    })
  })
})

export const { useGetShippingMethodsQuery } = apiSlice
