import { Country } from "@sixty-six-north/i18n"
import { Currency } from "@sixty-six-north/i18n"
import { Language } from "@sixty-six-north/i18n"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { PURGE } from "redux-persist"
import { DEFAULT_REGION, Region } from "./Region"

export interface RegionStateI {
  region?: Region
}

const initialState = {} as RegionStateI
export const RegionState = createSlice({
  name: "region",
  initialState,
  reducers: {
    setRegion: (state, action: PayloadAction<Region>) => {
      return { ...state, region: action.payload }
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState)
  }
})
