import { useRootSelector } from "../State"

const useReduxAccessToken = () => {
  return useRootSelector(it => it.accessTokenState.token)
}

const useAnonymousAccessToken = () => {
  return useRootSelector(it => it.accessTokenState.anonymousToken)
}

export const useAccessToken = () => {
  const accessToken = useReduxAccessToken()
  const anonymousAccessToken = useAnonymousAccessToken()

  return { accessToken, anonymousAccessToken }
}
