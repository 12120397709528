import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ErrorMessage {
  title?: string
  message: string
}

export const ErrorState = createSlice({
  name: "ErrorMessages",
  initialState: {} as Record<string, ErrorMessage | undefined>,
  reducers: {
    setMessage(
      state,
      action: PayloadAction<{ errorKey: string; message: ErrorMessage }>
    ) {
      const obj = {}
      obj[action.payload.errorKey] = action.payload.message
      return { ...state, ...obj }
    },
    clearMessage(state, action: PayloadAction<string>) {
      delete state[action.payload]
      return state
    },
    clearAll() {
      return {}
    }
  }
})
